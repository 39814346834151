import React from "react";
import NewIcon from "../../../resources/themes/dashboard-v1/icons/new.svg";
import {Link} from "react-router-dom";

const BookingEntry = ({ entry }) => {
  const { id, services, created_at } = entry;
  const name = services[0]?.title ?? "";
  const image = services[0]?.picture ?? "";

  const createdAtFormatted = new Date(created_at).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  return (
    <Link to={"/services/appointments"}>
      <div className="col-12 d-flex align-items-center adoption-entry py-2">
        <div className="col-3">
          <img src={image} alt="" className="pic" />
        </div>
        <div className="col-6">
          <div className="link">{name}</div>
          <p className="id">Appointment ID: #{id}</p>
          <p className="date">{createdAtFormatted}</p>
        </div>
        <div className="col-3 d-flex align-items-center justify-content-center p-0">
          <img src={NewIcon} alt="" />
        </div>
      </div>
    </Link>
  );
};

export default BookingEntry;
