import React, { useEffect, useState } from "react";
import {
  delete_shop,
  fetch_shops,
  approve_shop,
  reject_shop,
  export_shops,
} from "../../../services/admin/shops";
import ItemListingTable, {
  getTableUpdateCallback,
} from "../common/ItemListingTable";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ShopPopup from "./ShopPopup";


import { notify_promise } from "../../../services/utils/toasts";
import ListingPageControls from "../common/ListingPageControls";

const ShopListingPage = ({ pageState, setPageState }) => {
  const [shops, setShops] = useState([]);

  const [selectedShop, setSelectedShop] = useState(undefined);

  const [fields, setFields] = useState([]);
  const [pagination, setPagination] = useState({});

  const [filters, setFilters] = useState([]);
  const [newShopsCount, setNewShopsCount] = useState();

  const navigate = useNavigate();

  let { shopStatus } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search"));
  const [latestSearchQuery, setLatestSearchQuery] = useState(
    searchParams.get("search")
  );
  const [activeFilter, setActiveFilter] = useState(0);
  const [perPage, setPerPage] = useState(10);

  let filterMapping = {}
  filters.forEach((filter) => {
    filterMapping[filter.label] = filterMapping[filter.value]
  })

  const apiCall = [
    fetch_shops,
    {
      page: 0,
      status: shopStatus || "1",
      search: search ? search : "",
      perPage: perPage,
    },
  ];

  const handleApprove = (id) => {
    notify_promise(
      new Promise((resolve, reject) => {
        approve_shop([id])
          .then((res) => {
            console.log("Shop approved successfully:", res);
            setShops((shops) => shops.filter((item) => item.id !== id));
            setNewShopsCount((prevCount) => prevCount - 1);
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  const handleReject = (id) => {
    notify_promise(
      new Promise((resolve, reject) => {
        reject_shop([id])
          .then((res) => {
            console.log("Shop rejected successfully:", res);
            setShops((shops) => shops.filter((item) => item.id !== id));
            setNewShopsCount((prevCount) => prevCount - 1);
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  const handleDelete = (id) => {
    notify_promise(
      new Promise((resolve, reject) => {
        delete_shop([id])
          .then((res) => {
            setShops((prevPets) => {
              return prevPets.filter((p) => {
                return p.id !== id;
              });
            });
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  const openModal = (shopIndex) => {
    setSelectedShop(shops[shopIndex]);
    const popup = document.getElementById("shopPopup");
    popup.style.display = "flex";
  };

  useEffect(() => {
    updateTableInfo();
  }, [shopStatus, latestSearchQuery, perPage]);

  useEffect(() => {
    setSearch(searchParams.get("search"));
  }, [searchParams.get("search")]);

  useEffect(() => {
    setLatestSearchQuery(search);
  }, [search]);

  const updateTableInfo = getTableUpdateCallback({
    apiCall: apiCall,
    dataSetter: setShops,
    paginationSetter: setPagination,
    fieldSetter: setFields,
    buttons: [{ template: "view-modal", openModal: openModal }],
    setFilters: setFilters,
    setActiveFilter: setActiveFilter,
    navigate: navigate,
    numericStatus: shopStatus,
    type: "shop",
    handleApprove: handleApprove,
    handleReject: handleReject,
    handleDelete: handleDelete,
    setNewShopsCount: setNewShopsCount,
  });

  return (
    <div className="page-content py-3 px-4 bg-lightgray">
      <ShopPopup shop={selectedShop} />

      <ListingPageControls
        exportApi={export_shops}
        type="shop"
        addNewLink="/admin/shop/add"
        hasTitle={false}
        hasTabs={true}
        resultType="shop"
        filters={filters}
        newShopsCount={newShopsCount}
        setSearch={setSearchParams}
      />

      <ItemListingTable
        fields={fields}
        data={shops}
        pagination={pagination}
        updateTableInfo={updateTableInfo}
        modalTrigger={openModal}
        handleApprove={handleApprove}
        handleReject={handleReject}
        setPerPage={setPerPage}
        perPage={perPage}
      />
    </div>
  );
};

export default ShopListingPage;
