import React, { useState } from 'react';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
// import arrow from '../../resources/themes/dashboard-v1/icons/arrow-down-black.svg'
import { Navigate } from 'react-router-dom';
import { sendOtp } from '../../services/otpService';

const ResetPassword = (props) => {
    const [valid, setValid] = useState(false)
    const [error, setError] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [phoneNumberCC, setPhoneNumberCC] = useState('')

    const handlePhoneNumberChange = (value) => {
        if (typeof value === 'string') {
            const digitCount = (value.match(/\d/g) || []).length;
      
            if (digitCount > 1) {
                const parsedPhoneNumber = parsePhoneNumber(value);
      
                if (parsedPhoneNumber) {
                    setPhoneNumber(value);
                    const phoneNumberCc = parsedPhoneNumber.countryCallingCode;
                    setPhoneNumberCC(phoneNumberCc);
                } else {
                    setPhoneNumber('');
                    setPhoneNumberCC('');
                }
            } else {
                setPhoneNumber('');
                setPhoneNumberCC('');
            }
        } else {
            setPhoneNumber('');
            setPhoneNumberCC('');
        }
    };

    const handleSendOtp = () => {  
        sendOtp(phoneNumber)
          .then((response) => {
            if (response.status === "success") {
                setError("");
            } else {
                setError("Failed to send OTP");
            }
          })
          .catch((error) => {
            setError("Failed to send OTP");
          });
      }
    
    const handleNext = (e) => {
        if (!phoneNumber) {
            setError('Phone number is required.');
            setValid(false)
        } else{
            props.setPhoneData({
                phone_number: phoneNumber,
                phone_number_cc: phoneNumberCC,
            });
            setValid(true);
            handleSendOtp();
        }
    }

    if(valid){
        return <Navigate to="/resetpassword/otp"/>
    }


    return (
        <div className={props.class} method="POST">
            <h1 className="mb-1 text-center">Reset your password</h1>
            <p className="form-help">Enter your phone number to reset password.</p>

            {error && <div className="alert alert-danger mt-2">{error}</div>}

            <div className="mb-5 position-relative">
                
                <PhoneInput
                    defaultCountry="AE"
                    international
                    withCountryCallingCode
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange} />
            </div>

           <button type="submit" onClick={handleNext} className="btn btn-primary w-100 py-2 fw-bold">Continue</button>
        </div>
    );
}

export default ResetPassword;
