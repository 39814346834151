import React from 'react';
import {Link} from "react-router-dom";

const SuccessfulSignup = (props) => {
    return (
        <div className="center-content">
            <div className={`${props.class} text-center px-md-5 px-2`}>
                <div className='px-lg-5 px-0'>
                    <h2 className="mb-1 fw-bold text-center">Your documents have been successfully uploaded ! </h2>
                    <p className="form-help text-center">Your request is being reviewed , and you should recieve an email reply within 48 hours.</p>
                    <Link className="btn btn-primary py-2 mt-4 fw-bold w-100" to={"/admin"}>Go to Dashboard</Link>
                </div>
            </div>
        </div>
    );
}

export default SuccessfulSignup;
