import React, { useEffect, useState } from "react";
import photo from "../../../../resources/themes/dashboard-v1/img/carouselPhoto.png";
import next from "../../../../resources/themes/dashboard-v1/icons/arrow-right.svg";
import previous from "../../../../resources/themes/dashboard-v1/icons/arrow-left.svg";
import iconClose from "../../../../resources/themes/dashboard-v1/icons/close.svg";

import starSVG from "../../../../resources/themes/dashboard-v1/icons/star.svg";
const PetPopup = ({ pet }) => {
  if (pet === undefined) {
    return (
      <div
        id="petPopup"
        className="popup-container vw-100 vh-100  jusitfy-content-center align-items-center p-md-0 p-2"
      ></div>
    );
  }

  const closePopup = () => {
    const popup = document.getElementById("petPopup");
    popup.style.display = "none";
  };

  window.onkeydown = function (event) {
    if (event.keyCode === 27) {
      closePopup();
    }
  };

  return (
    <div
      id="petPopup"
      className="popup-container vw-100 vh-100  jusitfy-content-center align-items-center p-md-0 p-2"
    >
      <div
        onClick={closePopup}
        className="w-100 h-100 position-absolute outer-modal top-0 left-0"
      ></div>
      <div className="popup position-relative bg-white">
        <img
          onClick={closePopup}
          className="close-btn"
          src={iconClose}
          alt="close"
        />
        {/* <div>
          <img src={pet.image} className="d-block w-100" alt="photo" />
        </div> */}
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-label="Slide 1"
            ></button>
            {pet.images.map((img, index) => (
              <button
                key={img.id}
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={index + 1}
                className={index === 0 ? "active" : ""}
                aria-label={`Slide ${index + 2}`}
              ></button>
            ))}
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={pet.image}
                className="d-block w-100 carousel-image"
                alt="Main"
              />
            </div>
            {pet.images.map((img, index) => (
              <div key={img.id} className="carousel-item">
                <img
                  src={img.image}
                  className="d-block w-100 carousel-image"
                  alt={`Slide ${index + 1}`}
                />
              </div>
            ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        <div className="py-3 px-4">
          <div className="d-flex justify-content-between align-items-center border-bottom pb-4">
            <div className="d-flex flex-column gap-1">
              <span className="fs-4 fw-bold">{pet.name}</span>
              <span className="ligth-black">
                ID:#{pet.id} • {pet.gender_text}
              </span>
            </div>

            <span className="table-category adopted fw-bold text-white">
              {pet.status_text}
            </span>
          </div>

          <div className="border-bottom py-4 d-flex flex-column gap-4 ">
            <span className="fw-bold fs-5">pet's details</span>

            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <span className="details-title light-black mb-1">PET NAME</span>
                <span>{pet.name}</span>
              </div>
              <div className="d-flex flex-column">
                <span className="details-title light-black mb-1">
                  PET GENDER
                </span>
                <span>{pet.gender_text}</span>
              </div>
              <div className="d-flex flex-column">
                <span className="details-title light-black mb-1">
                  PET BIRTHDATE
                </span>
                <span>{pet.birth_date}</span>
              </div>
            </div>

            <div className="d-flex flex-column">
              <span className="details-title light-black mb-1">PET SIZES</span>
              <span>
                Weight : {pet.weight} Kg, Height : {pet.height} cm, Length :{" "}
                {pet.length} cm
              </span>
            </div>
          </div>

          {/*<div className="pt-4 d-flex flex-column gap-4 ">*/}
          {/*  <span className="fw-bold fs-5">Cat’s health record</span>*/}

          {/*  <div className="d-flex justify-content-between">*/}
          {/*    <div className="d-flex flex-column">*/}
          {/*      <span className="details-title light-black mb-1">*/}
          {/*        Last Vaccine date*/}
          {/*      </span>*/}
          {/*      <span>20/04/2020</span>*/}
          {/*    </div>*/}
          {/*    <div className="d-flex flex-column">*/}
          {/*      <span className="details-title light-black mb-1">*/}
          {/*        next Vaccine date*/}
          {/*      </span>*/}
          {/*      <span>20/04/2020</span>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default PetPopup;
