import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { notify_promise } from "../../../../services/utils/toasts";
import {
  delete_shop_pets,
  fetch_shop_pet,
} from "../../../../services/shelters/pets";
import { fetch_shelters } from "../../../../services/shelters/shelters";
import {
  fetch_transfers,
  add_transfers,
} from "../../../../services/shelters/transfers";
import CardListing from "../../CardListing";
import { fetch_vaccines } from "../../../../services/shelters/vaccines";
import { fetch_shop_adoptions } from "../../../../services/shelters/adoptions";
import ImageGallery from "../../common/ImageGallery";
import Select from "react-select";

const PetViewPage = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [hasPetData, setHasPetData] = useState(false);
  const [petData, setPetData] = useState({});
  const [shouldRedirectToIndex, setShouldRedirectToIndex] = useState(false);

  const [vaccines, setVaccines] = useState([]);
  const [adoptionRequests, setAdoptionRequests] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [selectedShelter, setSelectedShelter] = useState(null);
  const [reason, setReason] = useState("");
  const [shelters, setShelters] = useState([]);
  const [formData, setFormData] = useState({
    pet_id: parseInt(id),
    from_shelter_id: parseInt(localStorage["shop_id"]),
    to_shelter_id: "",
    reason: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getVaccines = () => {
    fetch_vaccines(id).then((response) => {
      if (response.data.length > 0) {
        setVaccines(response.data);
      }
    });
  };

  const getAdoptionRequests = () => {
    fetch_shop_adoptions({ pet_id: id }).then((response) => {
      if (response.data.length > 0) {
        setAdoptionRequests(response.data);
      }
    });
  };

  useEffect(() => {
    if (isLoading) {
      fetch_shop_pet(id).then((response) => {
        let petData = response.data ? response.data : {};
        setHasPetData(response.status === "ok");
        setPetData(petData);
        setIsLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    getVaccines();
    getAdoptionRequests();
    console.log(petData);
  }, []);

  useEffect(() => {
    fetch_shelters()
      .then((response) => {
        if (response.status === "ok") {
          const currentId = parseInt(localStorage["shop_id"]);
          const shelterOptions = Object.values(response)
            .map((shelter) => ({
              value: shelter.id,
              label: shelter.name,
            }))
            .filter((shelter) => shelter.value !== currentId);
          setShelters(shelterOptions);
        } else {
          console.error("Failed to fetch shelters:", response.error);
        }
      })
      .catch((error) => {
        console.error("Error fetching shelters:", error);
      });
  }, []);

  const handleDeletePet = () => {
    delete_shop_pets([id]).then((res) => {
      setShouldRedirectToIndex(res.status === "ok");
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const updatedFormData = {
      ...formData,
      to_shelter_id: selectedShelter.value,
    };

    add_transfers(updatedFormData)
      .then((response) => {
        console.log("Transfer added successfully", response);

        setFormData({
          pet_id: parseInt(id),
          from_shelter_id: parseInt(localStorage["shop_id"]),
          to_shelter_id: "",
          reason: "",
        });

        setSelectedShelter(null);
        setIsModalOpen(false);
        closeModal();
      })
      .catch((error) => {
        console.error("Error adding transfer:", error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const closeModal = () => {
    const closeButton = document.getElementById("closeButton");
    if (closeButton) {
      closeButton.click();
    }
  };

  if (shouldRedirectToIndex) {
    return <Navigate to={"/admin/pets/list"} />;
  }

  let content = "";
  if (isLoading) {
    return <></>;
  } else if (!hasPetData) {
    content = (
      <div>
        <h3>No Such Pet</h3>
      </div>
    );
  }

  return (
    <section className="py-3 px-4 flex-grow-1 flex-shrink-1 bg-lightgray">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h1>{petData.name}</h1>
          </div>
          <div className="col-md-4">
            <div className="d-flex flex-row gap-1 justify-content-end">
              <Link to={"/admin/pets/edit/" + id}>
                <button className={"btn btn-primary btn-light-primary"}>
                  Edit
                </button>
              </Link>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#transferModal"
              >
                Transfer Pet
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <PetOverView petData={petData} />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12">
                <div className="p-3  bg-white mb-3 rounded-3">
                  <h3>Pet Details</h3>
                  {petData.description.length > 0 ? (
                    petData.description
                  ) : (
                    <p>No details provided</p>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <CardListing
                  title="Adoption Requests"
                  type="orders"
                  entries={adoptionRequests}
                />
              </div>
              <div className="col-md-6">
                <CardListing
                  title="Vaccines"
                  type="vaccines"
                  entries={vaccines}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="p-3 bg-white mt-3 rounded-3">
                  <h3>Pet Images</h3>
                  {petData.images.length > 0 ? (
                    <ImageGallery images={petData.images} />
                  ) : (
                    <p>No images provided</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="transferModal"
        tabIndex="-1"
        aria-labelledby="transferModalLabel"
        aria-hidden={!showModal}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="transferModalLabel">
                Transfer Pet
              </h5>
              <button
                type="button"
                id="closeButton"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleFormSubmit}>
                <div className="form-group mb-3">
                  <label htmlFor="shelterSelect">Select Shelter</label>
                  <Select
                    options={shelters}
                    name="to_shelter_id"
                    value={selectedShelter}
                    onChange={(option) => setSelectedShelter(option)}
                    isSearchable={true}
                    placeholder="Search and select a shelter"
                  />
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="reasonTextarea">Reason / Details</label>
                  <textarea
                    id="reasonTextarea"
                    className="form-control"
                    rows="3"
                    name="reason"
                    value={formData.reason}
                    onChange={(e) =>
                      setFormData({ ...formData, reason: e.target.value })
                    }
                  ></textarea>
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary"
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PetOverView = ({ petData }) => {
  if (!petData) {
    return <></>;
  }
  return (
    <div className={"row"}>
      <div className="col-md-12">
        <img src={petData.image} alt="Pet" className="img-fluid rounded-3" />
      </div>
      <div className="col-md-12">
        <div className="table-responsive">
          <table className="table table-striped">
            <tbody>
              <tr>
                {" "}
                <th className="fw-bold">Species</th> <td>{petData.type.title}</td>{" "}
              </tr>
              <tr>
                <th className="fw-bold">Age</th>
                <td>{petData.age_text}</td>
              </tr>
              <tr>
                <th className="fw-bold">Adoption Status</th>
                <td>
                  <span className="badge bg-info">{petData.status_text}</span>
                </td>
              </tr>
              <tr>
                <th className="fw-bold">Height</th>
                <td>{petData.height_text}</td>
              </tr>
              <tr>
                <th className="fw-bold">Weight</th>
                <td>{petData.weight_text}</td>
              </tr>
              <tr>
                <th className="fw-bold">Length</th>
                <td>{petData.length_text}</td>
              </tr>
              <tr>
                <th className="fw-bold">Good With Kids</th>
                <td>{petData.good_with_kids ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th className="fw-bold">Good With Other Pets</th>
                <td>{petData.good_with_other_pets ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th className="fw-bold">Available For Fostering</th>
                <td>{petData.available_for_fostering ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th className="fw-bold">Has Special Needs</th>
                <td>{petData.has_special_needs ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th className="fw-bold">Aggression Level</th>
                <td>{petData.aggression_level}</td>
              </tr>
              <tr>
                <th className="fw-bold">Admission Date</th>
                <td>{petData.admission_date}</td>
              </tr>
              <tr>
                <th className="fw-bold">Adoption Fees</th>
                <td>{petData.adoption_fees}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PetViewPage;
