import React, { useEffect, useState } from "react";
import {
  delete_shop_pets,
  export_shop_pets,
  fetch_shop_pets,
  IMPORT_API,
} from "../../../../services/shelters/pets";
import ItemListingTable, {
  getTableUpdateCallback,
} from "../../common/ItemListingTable";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { notify_promise } from "../../../../services/utils/toasts";
import PetPopup from "./PetPopup";
import DataFilterButtons, { STYLE_LINKS } from "../../common/DataFilterButtons";
import ImportFilePopup from "../../common/ImportFilePopup";
import ListingPageControls from "../../common/ListingPageControls";

const PetListingPage = ({ pageState, setPageState }) => {
  const [pets, setPets] = useState([]);

  const [selectedPet, setSelectedPet] = useState(undefined);

  const [fields, setFields] = useState([]);
  const [pagination, setPagination] = useState({});

  const [filters, setFilters] = useState([]);
  const [activeFilter, setActiveFilter] = useState("all");

  const { petType } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search"));
  const [latestSearchQuery, setLatestSearchQuery] = useState(
    searchParams.get("search")
  );

  const [perPage, setPerPage] = useState(10);

  const apiCall = [
    fetch_shop_pets,
    { page: 0, type: petType, search: search ? search : "", perPage: perPage },
  ];
  const navigate = useNavigate();

  const deleteItem = (id) => {
    notify_promise(
      new Promise((resolve, reject) => {
        delete_shop_pets([id])
          .then((res) => {
            setPets((prevPets) => {
              return prevPets.filter((p) => {
                return p.id !== id;
              });
            });
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  const openModal = (petIndex) => {
    setSelectedPet(pets[petIndex]);
    const popup = document.getElementById("petPopup");
    popup.style.display = "flex";
  };

  const updateTableInfo = getTableUpdateCallback({
    apiCall: apiCall,
    dataSetter: setPets,
    paginationSetter: setPagination,
    fieldSetter: setFields,
    buttons: [
      { template: "view-modal" },
      {
        template: "edit",
        getRedirectURL: (id) => {
          return "/admin/pets/edit/:id";
        },
      },
      {
        template: "delete",
        onClick: (id) => {
          deleteItem(id);
        },
      },
    ],
    setFilters: setFilters,
    setActiveFilter: setActiveFilter,
    navigate: navigate,
    type: "pet",
  });

  useEffect(() => {
    setSearch(searchParams.get("search"));
  }, [searchParams.get("search")]);

  useEffect(() => {
    setLatestSearchQuery(search);
  }, [search]);

  useEffect(() => {
    updateTableInfo(0);
  }, [petType, latestSearchQuery, perPage]);

  return (
    <div className="page-content py-3 px-4 bg-lightgray">
      <PetPopup pet={selectedPet} />
      <ImportFilePopup upload_endpoint={IMPORT_API} />

      <ListingPageControls
        exportApi={export_shop_pets}
        type="pet"
        addNewLink="/admin/pets/add"
        hasTitle={false}
        resultType="pet"
        setSearch={setSearchParams}
      />

      {filters.length > 0 ? (
        <DataFilterButtons filters={filters} style={STYLE_LINKS} />
      ) : (
        ""
      )}
      <ItemListingTable
        fields={fields}
        data={pets}
        pagination={pagination}
        updateTableInfo={updateTableInfo}
        modalTrigger={openModal}
        setPerPage={setPerPage}
        perPage={perPage}
      />
    </div>
  );
};

export default PetListingPage;
