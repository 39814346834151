import React, { useState, useEffect, useRef } from "react";
import {
  fetch_support_tickets,
  mark_resolved,
} from "../../../services/support";

import {
  send_support_message,
  fetch_new_messages,
} from "../../../services/supportMessages";
import attachIcon from "../../../resources/themes/dashboard-v1/icons/attach.svg";
import sendIcon from "../../../resources/themes/dashboard-v1/icons/send.svg";
import noTickets from "../../../resources/themes/dashboard-v1/icons/no-tickets.svg";
import { notify_promise } from "../../../services/utils/toasts";
import avatar from "../../../resources/themes/dashboard-v1/icons/avatar.svg";

const AdminSupportPage = () => {
  const [supportTickets, setSupportTickets] = useState([]);
  const [ongoingTickets, setOngoingTickets] = useState([]);
  const [resolvedTickets, setResolvedTickets] = useState([]);
  const [messageMap, setMessageMap] = useState({});
  const [selectedTicket, setSelectedTicket] = useState({});
  const [textBoxValues, setTextBoxValues] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [lastOpenedChat, setLastOpenedChat] = useState(null);
  const [lastFetchedTime, setLastFetchedTime] = useState(
    new Date().toISOString()
  );
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  let userID = localStorage.getItem("user_id");

  const chatContainerRef = useRef(null);
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  function formatDate(inputDate) {
    const createdAtDate = new Date(inputDate);

    const formattedDate = createdAtDate.toLocaleDateString("en-US", {
      month: "short", // Display month as three letters (e.g., Jul)
      day: "numeric", // Display day as a number (e.g., 23)
      year: "numeric", // Display year (e.g., 2023)
    });

    return formattedDate; // formattedDate will now be in the format "Jul 23, 2023"
  }

  const fetchNewMessages = async () => {
    if (lastFetchedTime) {
      const ticketId = selectedTicket.id;
      if (ticketId) {
        try {
          const data = await fetch_new_messages(ticketId, lastFetchedTime);
          // if (data.messages.length > 0) {
          //   const latestMessage = data.messages[data.messages.length - 1];
          //   setLastFetchedTime((prevTime) =>
          //     Math.max(prevTime, latestMessage.created_at)
          //   );

          //   setMessageMap((prevMessageMap) => {
          //     return {
          //       ...prevMessageMap,
          //       [ticketId]: [...prevMessageMap[ticketId], ...data.messages],
          //     };
          //   });
          //   console.log(messageMap);
          //   setSelectedTicket((prevSelectedTicket) => {
          //     return {
          //       ...prevSelectedTicket,
          //       messages: messageMap[selectedTicket.id] || [],
          //     };
          //   });
          // }

          // if (data.messages.length > 0) {
          //   const latestMessage = data.messages[data.messages.length - 1];
          //   setLastFetchedTime((prevTime) =>
          //     Math.max(prevTime, latestMessage.created_at)
          //   );

          //   setMessageMap((prevMessageMap) => {
          //     return {
          //       ...prevMessageMap,
          //       [ticketId]: [...prevMessageMap[ticketId], ...data.messages],
          //     };
          //   });

          //   setSelectedTicket((prevSelectedTicket) => {
          //     return {
          //       ...prevSelectedTicket,
          //       messages: [...messageMap[ticketId], ...data.messages],
          //     };
          //   });

          //   scrollToBottom();
          // }

          if (data.messages.length > 0) {
            const latestMessage = data.messages[data.messages.length - 1];
            setLastFetchedTime((prevTime) =>
              Math.max(prevTime, latestMessage.created_at)
            );

            setMessageMap((prevMessageMap) => {
              return {
                ...prevMessageMap,
                [ticketId]: [...prevMessageMap[ticketId], ...data.messages],
              };
            });

            setSelectedTicket((prevSelectedTicket) => {
              const updatedMessages = [
                ...prevSelectedTicket.messages,
                ...data.messages,
              ];

              return {
                ...prevSelectedTicket,
                messages: updatedMessages,
              };
            });

            scrollToBottom();
          }

        } catch (error) {
          console.error("Error fetching new messages:", error);
        }
      }
    }
  };

  const fetchSupportTickets = () => {
    fetch_support_tickets()
      .then((res) => {
        setOngoingTickets(res.data.filter((ticket) => ticket.status === 0));

        setResolvedTickets(res.data.filter((ticket) => ticket.status === 1));

        setSupportTickets(res.data);

        if (res.data.length > 0) {
          let messages = {};

          res.data.map((ticket) => {
            messages[ticket.id] = ticket.messages;
          });

          setMessageMap(messages);

          let supportTextBookVals = {};
          res.data.map((ticket) => {
            supportTextBookVals[ticket.id] = "";
          });
          setTextBoxValues(supportTextBookVals);

          const latestTicket = res.data.reduce((latest, ticket) => {
            if (ticket.messages.length > 0) {
              const latestMessage = ticket.messages[ticket.messages.length - 1];
              if (!latest || latestMessage.created_at > latest) {
                return latestMessage.created_at;
              }
            }
            return latest;
          }, null);

          if (latestTicket) {
            setLastFetchedTime(latestTicket);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      send_message();
    }
  };

  const chatThread =
    selectedTicket.messages === undefined
      ? ""
      : selectedTicket.messages.map((message) => {
          let iAmNotSender = message.sender_id != userID;
          let image = message.sender_id ? message.sender_id : avatar;
          let extraLiClasses = iAmNotSender ? "" : " flex-row-reverse";
          let extraMsgClasses = iAmNotSender
            ? " bg-gray-light"
            : " bg-primary text-white";
          return (
            <li
              key={message.id}
              className={
                "list-group-item d-flex gap-2 align-items-center" +
                extraLiClasses
              }
            >
              {imageError ? (
                <img className="me-3" src={avatar} alt="Dummy Image" />
              ) : (
                <img
                  src={image}
                  alt="Sender Image"
                  width={50}
                  height={50}
                  onError={handleImageError}
                />
              )}
              <p className={"m-0 p-3 rounded-4" + extraMsgClasses}>
                {message.message_body}
              </p>
            </li>
          );
        });

  const markAsResolved = (ticketId) => {
    notify_promise(
      new Promise((resolve, reject) => {
        mark_resolved(ticketId)
          .then((res) => {
            setOngoingTickets((ticket) =>
              ticket.filter((item) => item.id !== ticketId)
            );
            setResolvedTickets([...resolvedTickets, res.data]);
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  const send_message = async () => {
    try {
      const res = await send_support_message(
        selectedTicket.id,
        textBoxValues[selectedTicket.id],
        userID
      );

      if (res.status === "ok") {
        await fetchNewMessages();
        await fetchSupportTickets();
        await setSelectedTicket(res.ticket[0]);
        scrollToBottom();
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }

    setTextBoxValues((prevTextBoxValues) => {
      return {
        ...prevTextBoxValues,
        [selectedTicket.id]: "",
      };
    });
  };


  const handleChatModalOpen = (ticket) => {
    setSelectedTicket(ticket);
    //setLastOpenedChat(ticket.id);
    setShowModal(true);
  };

  useEffect(() => {
    console.log("Component is mounting or re-rendering.");
    fetchSupportTickets();
  }, [selectedTicket]);

  useEffect(() => {
    const pollInterval = setInterval(() => {
      fetchNewMessages();
    }, 3000);

    return () => clearInterval(pollInterval);
  }, [selectedTicket, messageMap]);

  useEffect(() => {
    scrollToBottom();
  }, [messageMap, selectedTicket]);

  useEffect(() => {
    console.log("Updated Message Map:", messageMap);
  }, [messageMap]);


  return (
    <section className="page-content py-3 px-4 bg-lightgray flex-grow-1">
      <div className="row">
        {ongoingTickets.length > 0 && (
          <div className="col-md-6">
            <div className="col-description">
              <h2>Ongoing Tickets</h2>
            </div>
            {ongoingTickets.length > 0 && (
              <div className="accordion" id="ongoingticketAccordion">
                {ongoingTickets.map((item, index) => (
                  <div className="accordion-item" key={index}>
                    <h2
                      className="accordion-header"
                      id={`ongoingHeading${index}`}
                    >
                      <button
                        className={"accordion-button collapsed"}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#ongoingCollapse${index}`}
                        aria-expanded={"false"}
                        aria-controls={`ongoingCollapse${index}`}
                      >
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ width: "90%" }}
                        >
                          <span>{item.title}</span>
                          <span className="badge bg-primary">
                            {formatDate(item.created_at)}
                          </span>
                        </div>
                      </button>
                    </h2>
                    <div
                      id={`ongoingCollapse${index}`}
                      className={`accordion-collapse collapse`}
                      aria-labelledby={`ongoingHeading${index}`}
                      data-bs-parent="#ongoingticketAccordion"
                    >
                      <div
                        className="accordion-body"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></div>
                      <div className="d-flex justify-content-around my-3">
                        <button
                          type="button"
                          className="btn btn-primary no-change"
                          data-bs-toggle="modal"
                          data-bs-target="#chatModal"
                          onClick={() => handleChatModalOpen(item)}
                        >
                          View Chat
                        </button>
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => markAsResolved(item.id)}
                        >
                          Mark as Resolved
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {resolvedTickets.length > 0 && (
          <div className="col-md-6">
            <div className="col-description">
              <h2>Resolved Tickets</h2>
            </div>
            {resolvedTickets.length > 0 && (
              <div className="accordion" id="resolvedticketAccordion">
                {resolvedTickets.map((item, index) => (
                  <div className="accordion-item" key={index}>
                    <h2
                      className="accordion-header"
                      id={`resolvedHeading${index}`}
                    >
                      <button
                        className={"accordion-button collapsed"}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#resolvedCollapse${index}`}
                        aria-expanded={"false"}
                        aria-controls={`resolvedCollapse${index}`}
                      >
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{ width: "90%" }}
                        >
                          <span>{item.title}</span>
                          <span className="badge bg-primary">
                            {formatDate(item.created_at)}
                          </span>
                        </div>
                      </button>
                    </h2>
                    <div
                      id={`resolvedCollapse${index}`}
                      className={`accordion-collapse collapse`}
                      aria-labelledby={`resolvedHeading${index}`}
                      data-bs-parent="#resolvedticketAccordion"
                    >
                      <div
                        className="accordion-body"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></div>
                      <div className="d-flex justify-content-center my-3">
                        <button
                          type="button"
                          className="btn btn-primary no-change"
                          data-bs-toggle="modal"
                          data-bs-target="#chatModal"
                          onClick={() => handleChatModalOpen(item)}
                        >
                          View Chat
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {ongoingTickets.length === 0 && resolvedTickets.length === 0 && (
          <img src={noTickets} alt="no tickets icon" width={60} height={60} className="mt-5 mb-2"/>
        )}
        {ongoingTickets.length === 0 && resolvedTickets.length === 0 && (
          <p className="text-center fs-4 fw-bold">No support tickets</p>
        )}
      </div>
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="chatModal"
        tabIndex="-1"
        aria-labelledby="chatModalLabel"
        aria-hidden={!showModal}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="chatModalLabel">
                Support Chat &nbsp;
                <span className="fs-6 text-muted">#{selectedTicket.id}</span>
              </h5>
              <button
                type="button"
                id="closeButton"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="bg-white">
                <div
                  className={
                    "adoption-pet-info d-flex flex-row justify-content-between"
                  }
                ></div>

                <div className="gradient-sep"></div>

                <div
                  id={"chatMessageBox"}
                  className="list-group chat-message-box bg-white p-2"
                >
                  <div className="messages scrollableY max-height-500">
                    {chatThread}
                    <div ref={chatContainerRef}></div>
                  </div>

                  <div className="chat-input-box input-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type a message"
                      value={
                        selectedTicket.id
                          ? textBoxValues[selectedTicket.id]
                          : ""
                      }
                      onChange={(e) => {
                        setTextBoxValues((prevTextBoxValues) => {
                          return {
                            ...prevTextBoxValues,
                            [selectedTicket.id]: e.target.value,
                          };
                        });
                      }}
                      onKeyPress={handleKeyPress}
                    />
                    <div className="chat-btn-group d-flex flex-row position-absolute">
                      <button className="btn" onClick={send_message}>
                        <img
                          src={sendIcon}
                          alt="send icon"
                          width={30}
                          height={30}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminSupportPage;
