import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logoWhite from "../../resources/themes/dashboard-v1/img/logo-white.png";
import iconEnvelope from "../../resources/themes/dashboard-v1/icons/envelope.svg";
import BusinessCategory from "./businessCategory";
import RepresenterInfo from "./representerInfo";

import BusinessInfo from "./businessInfo";
// import BankAccountInfo from './bankAccountInfo';
import SuccessfulSignup from "./successfulSignup";
import FailSignup from "./failSignup";
import TimeLine from "./timeLine";
import VerifyNumber from "../resetPassword/VerifyNumber";

const SignupDataPage = () => {
  //Time Line status
  const [stepIndex, setStepIndex] = useState(0);
  const [progress, setProgress] = useState(5);

  const [registrationData, setRegistrationData] = useState({
    businessCategory: {
      isProductProvider: false,
      isServiceProvider: false,
      isAdoptionShelter: false,
    },
    representerInfo: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number_cc: "",
      phone_number: "",
      government_id: "",
      password: "",
    },
    businessInfo: {
      shopName: "",
      businessDescription: "",
      phoneNumber: "",
      license: "",
      businessLogo: "",
      // area: "",
      // city: "",
    },
    bankAccountInfo: {
      bankName: "",
      category: "",
      accountNumber: "",
      iban: "",
    },
  });

  const navigate = useNavigate();

  const total_stages = 4;
  const updateTimeLine = (index) => {
    if (index < 0) {
      index = 0;
    }
    if (index > 6) {
      index = 6;
    }

    setStepIndex(index);
    setProgress(parseInt(((index + 1) / total_stages) * 100, 10));
  };

  const stageComponents = [
    <BusinessCategory
      updateTimeLine={updateTimeLine}
      registrationData={registrationData}
      setRegistrationData={setRegistrationData}
    />,
    <RepresenterInfo
      class={"signup-form p-3"}
      updateTimeLine={updateTimeLine}
      registrationData={registrationData}
      setRegistrationData={setRegistrationData}
    />,
    <VerifyNumber
      class={"signup-form p-3"}
      inputText={"Confirm"}
      path={"/signupData/businessInfo"}
      updateTimeLine={updateTimeLine}
      registrationData={registrationData}
      setRegistrationData={setRegistrationData}
    />,
    <BusinessInfo
      class={"signup-form pt-0 p-3"}
      updateTimeLine={updateTimeLine}
      registrationData={registrationData}
      setRegistrationData={setRegistrationData}
    />,
    <SuccessfulSignup class={"signup-form p-3"} />,
    <FailSignup class={"signup-form p-3"} updateTimeLine={updateTimeLine} />,
  ];

  return (
    <section className="signup-data-section login-section container-fluid">
      <div className="row full-screen-height">
        <div className="col-md-6 account-left-col flex-container-center">
          <div className="bg-doodles">
            <div className="m-md-4 mt-md-3 logo-container">
              <img src={logoWhite} alt="Logo" className="logo" />
            </div>

            <h2 className="desktop-only fw-bold title mb-1 text-white ms-4 mt-3">
              Welcome to Pawpaws 🎉
            </h2>
            <p className="desktop-only form-help text-white m-0 ms-4">
              Enter your info to create new account.
            </p>

            <TimeLine index={stepIndex} progress={progress} />

            <div className="full-width px-md-3 px-0 mt-4">
              <div className="mobile-only signup-form w-100 p-3 bg-white">
                {stageComponents[stepIndex]}
              </div>
            </div>

            <div className="bg-footer flex-container-center">
              <p className="text-white">© Pawpaws 2023</p>
              <a
                className="link-plain text-white email-link"
                href="mailto:help@pawpaws.com"
                target="_blank"
              >
                <img src={iconEnvelope} alt="envelope" />
                <span>help@pawpaws.com</span>
              </a>
            </div>
          </div>
        </div>
        <div
          className="col-md-6 desktop-only full-screen-height overflow-scroll"
          id="form-container"
        >
          <div className={"text-left p-3"}>
            {stepIndex > 0 ? (
              <button
                className="btn btn-light-primary btn-padded"
                onClick={(e) => {
                  e.preventDefault();
                  updateTimeLine(stepIndex - 1, 15);
                }}
              >
                Go Back
              </button>
            ) : stepIndex === 0 ? (
              <button
                className="btn btn-light-primary btn-padded"
                onClick={(e) => {
                  e.preventDefault();
                  // i want to go to "/signup"
                  navigate("/signup");
                }}
              >
                Go Back
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="full-height flex-container-center flex-column">
            <div className="flex-container-column flex-container-center h-100 full-width">
              {stageComponents[stepIndex]}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignupDataPage;
