import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ItemListingTable, {
  getTableUpdateCallback,
} from "../common/ItemListingTable";
import {
  fetch_employees,
  export_employees,
  delete_employees,
  IMPORT_API,
} from "../../../services/clinic/employees";
import ListingPageControls from "../common/ListingPageControls";
import ImportFilePopup from "../common/ImportFilePopup";
import { notify_promise } from "../../../services/utils/toasts";

const EmployeeListingPage = () => {
  const [fields, setFields] = useState([]);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [perPage, setPerPage] = useState(10);

  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search"));
  const [latestSearchQuery, setLatestSearchQuery] = useState(
    searchParams.get("search")
  );

  const apiCall = [
    fetch_employees,
    { page: 0, search: search ? search : "", perPage: perPage },
  ];

  const deleteItem = (id) => {
    notify_promise(
      new Promise((resolve, reject) => {
        delete_employees([id])
          .then((res) => {
            setData((prev) => {
              return prev.filter((p) => {
                return p.id !== id;
              });
            });
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  const updateTableInfo = getTableUpdateCallback(
    {
    apiCall: apiCall,
    dataSetter: setData,
    paginationSetter: setPagination,
    fieldSetter: setFields,
    buttons: [
      {
        template: "edit",
        getRedirectURL: (id) => {
          return "/admin/employees/edit/:id";
        },
      },
      {
        template: "delete",
        onClick: (id) => {
          deleteItem(id);
        },
      },
    ]}
  );

  useEffect(() => {
    updateTableInfo(0);
    setSearch(searchParams.get("search"));
    setLatestSearchQuery(search);
  }, [searchParams.get("search"), latestSearchQuery, perPage]);

  return (
    <div className="page-content py-3 px-4 bg-lightgray">
      <ImportFilePopup upload_endpoint={IMPORT_API} />
      <ListingPageControls
        exportApi={export_employees}
        type="employee"
        addNewLink="/admin/employees/add"
        hasTitle={false}
        hasTabs={false}
        resultType="employee"
        setSearch={setSearchParams}
      />
      <ItemListingTable
        updateTableInfo={updateTableInfo}
        fields={fields}
        data={data}
        pagination={pagination}
        setPerPage={setPerPage}
        perPage={perPage}
      />
    </div>
  );
};

export default EmployeeListingPage;
