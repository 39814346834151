import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const PieChart = ({ data, width, height, isView }) => {
  const chartRef = useRef();

  width = isView ? width / 2 : width;
  height = isView ? height / 2 : height;

  useEffect(() => {

    d3.select(chartRef.current).selectAll("svg").remove();
    
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", isView ? width * 1.5 : width)
      .attr("height", isView ? height * 1.5 : height);

    const radius = Math.min(width, height) / 2;

    // Define custom colors
    const colors = [
      "#4339F2",
      "#FFB200",
      "#FF3A29",
      "#2CA02C",
      "#9467BD",
      "#8C564B",
      "#E377C2",
      "#7F7F7F",
      "#BCBD22",
      "#17BECF",
      "#FF9896",
      "#AEC7E8",
    ];
    const color = d3.scaleOrdinal().range(colors);

    const pie = d3.pie().value((d) => d.value);

    const arc = d3.arc().innerRadius(0).outerRadius(radius);

    if (isView) {
      svg
        .append("rect")
        .attr("width", width * 1.5)
        .attr("height", height * 1.5)
        .attr("fill", "white")
        .attr("rx", 15)
        .attr("ry", 15);
    }

    const arcs = svg
      .selectAll(".arc")
      .data(pie(data))
      .enter()
      .append("g")
      .attr("class", "arc")
      .attr(
        "transform",
        `translate(${width / (isView ? 1.33 : 2)}, ${
          height / (isView ? 1.33 : 2)
        })`
      );

    arcs
      .append("path")
      .attr("d", arc)
      .attr("fill", (d) => color(d.data.label));

    if (isView) {
      const text = arcs
        .append("text")
        .style("display", "none")
        .style("fill", "white")
        .style("font-size", "24px")
        .style("font-weight", "600")
        .attr("transform", (d) => {
          const [x, y] = arc.centroid(d);
          return `translate(${x}, ${y})`;
        })
        .attr("text-anchor", "middle")
        .attr("dy", "0.35em")
        .text((d) => `${d.data.value.toLocaleString()}`);

      arcs.on("mouseover", function (d) {
        // Mouseover event handler
        const { data: partitionData } = this.__data__;
        d3.select(this).select("text").style("display", "block");
        d3.select(this)
          .select("path")
          .attr("fill", d3.color(color(partitionData.label)).brighter(0.5));
        const [x, y] = arc.centroid(d);
        d3.select("#tooltip")
          .style("left", `${width / (isView ? 1.33 : 2) + x}px`)
          .style("top", `${height / (isView ? 1.33 : 2) + y}px`)
          .text(`${d.data.label}: ${d.data.value}`);
      });

      arcs.on("mouseout", function () {
        // Mouseout event handler
        d3.select(this).select("text").style("display", "none");
        const { data: partitionData } = this.__data__;
        d3.select(this).select("path").attr("fill", color(partitionData.label));
        d3.select("#tooltip").text("").style("left", "-9999px");
      });

      if (isView) {
        const text = arcs
          .append("text")
          .style("display", "none")
          .style("fill", "white")
          .style("font-size", "12px")
          .style("font-weight", "bold")
          .attr("transform", (d) => {
            const [x, y] = arc.centroid(d);
            return `translate(${x}, ${y})`;
          })
          .attr("text-anchor", "middle")
          .attr("dy", "0.35em")
          .text((d) => `${d.data.label} : ${d.data.value}`);

        // Legend
        const legend = svg
          .append("g")
          .attr("class", "legend")
          .attr("transform", `translate(${width + 120}, ${height / 6})`);

        legend
          .selectAll("rect")
          .data(data)
          .enter()
          .append("rect")
          .attr("y", (d, i) => i * 25)
          .attr("width", 15)
          .attr("height", 15)
          .attr("fill", (d) => color(d.label))
          .style("font-size", "18px")
          .style("font-weight", "bold");

        legend
          .selectAll("text")
          .data(data)
          .enter()
          .append("text")
          .attr("x", 25)
          .attr("y", (d, i) => i * 25 + 14)
          .text((d) => d.label);
      }
    }
  }, [data, width, height, isView]);

  return (
    <div>
      <div id="tooltip" style={{ display: "none" }}></div>
      <div ref={chartRef}></div>
    </div>
  );
};

export default PieChart;
