import React, { useState } from "react";

const StylePreviewBox = ({ formData }) => {
  const getFileURL = (image) => {
    if (image instanceof File) {
      return URL.createObjectURL(image);
    } else {
      return image;
    }
  };
  const previewStyle = {
    backgroundImage: `url(${getFileURL(formData.background_image)})`,
    backgroundColor: formData.background_color,
    color: formData.font_color,
    fontSize: `${formData.font_size}px`,
    borderColor: formData.foreground_color,
    borderStyle: "solid",
    borderRadius: "5px",
    padding: "10px",
    margin: "10px",
    height: "100px",
    textAlign: "center"
  };

  return (
    <>
        <h4 className="fw-bold text-center mb-4">Preview</h4>
        <div className="style-preview-box" style={previewStyle}>
        <p className="m-0">Your Text</p>
        </div>
    </>
  );
};

export default StylePreviewBox;
