import React, { useState } from "react";

const CustomRadio = ({ options, setSelectedButton, selectedButton }) => {

  return (
    <div>
      {options.map((option, index) => (
        <button
          key={index}
          type="button"
          className={`btn ${
            selectedButton === option.value
              ? "btn-outline-primary"
              : "btn-outline-secondary"
          } rounded-pill me-1 mb-2`}
          onClick={() => setSelectedButton(option.value)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default CustomRadio;
