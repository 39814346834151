import React, { useState, useEffect } from 'react';

const AvailabilitySettings = ({ onChange, initialAvailability }) => {
    const defaultAvailability = {
        monday: { available: true, from: '06:00', to: '18:00' },
        tuesday: { available: true, from: '06:00', to: '18:00' },
        wednesday: { available: true, from: '06:00', to: '18:00' },
        thursday: { available: true, from: '06:00', to: '18:00' },
        friday: { available: true, from: '06:00', to: '18:00' },
        saturday: { available: true, from: '06:00', to: '18:00' },
        sunday: { available: false, from: '06:00', to: '18:00' },
    };
    // Initialize state with the passed initialAvailability or use default values if not provided
    const [availability, setAvailability] = useState(initialAvailability);

    useEffect(() => {
        let availability = defaultAvailability
        if (Object.keys(initialAvailability).length === 7) {
            availability = initialAvailability;
        }
        setAvailability(availability);
    }, [initialAvailability]);

    const handleCheckboxChange = (day) => {
        const updatedAvailability = {
            ...availability,
            [day]: { ...availability[day], available: !availability[day].available },
        };
        setAvailability(updatedAvailability);
        onChange(updatedAvailability);
    };

    const handleTimeChange = (day, timeType, value) => {
        const updatedAvailability = {
            ...availability,
            [day]: { ...availability[day], [timeType]: value },
        };
        setAvailability(updatedAvailability);
        onChange(updatedAvailability);
    };

    return (
        <div>
            {Object.keys(availability).map((day) => {
                let rowActiveClass = ""
                if (!availability[day].available) {
                    rowActiveClass += " disabled-availability-row";
                }
                return <div key={day} className={"d-flex gap-4 p-2 day-availability-row " + rowActiveClass}>
                    <div className={"d-flex align-items-center justify-content-end gap-2"}>
                        <input
                            type="checkbox"
                            checked={availability[day].available}
                            onChange={() => handleCheckboxChange(day)}
                        />
                        <label className={"m-0"} style={{minWidth: 90}}>
                            {day.charAt(0).toUpperCase() + day.slice(1)}
                        </label>
                    </div>
                    <div className={"d-flex availability-time-input-container align-items-center gap-2"}>
                        <div className={"d-flex availability-checkbox-input-container align-items-center justify-content-end gap-2"}>
                            <label className={"m-0"}>
                                From:
                            </label>

                            <input
                                type="time"
                                value={availability[day].from}
                                onChange={(e) => handleTimeChange(day, 'from', e.target.value)}
                            />
                        </div>
                        <div className={"d-flex availability-checkbox-input-container align-items-center justify-content-end gap-2"}>
                            <label className={"m-0"}>
                                To:
                            </label>

                            <input
                                type="time"
                                value={availability[day].to}
                                onChange={(e) => handleTimeChange(day, 'to', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            })}
        </div>
    );
};

export default AvailabilitySettings;