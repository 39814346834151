import React, { useEffect, useState } from "react";
import {
  fetch_blogs,
  delete_blog,
  export_blogs,
} from "../../../services/admin/blogs";
import ItemListingTable, {
  getTableUpdateCallback,
} from "../common/ItemListingTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import ShopPopup from "./ShopPopup";
import { notify_promise } from "../../../services/utils/toasts";
import ListingPageControls from "../common/ListingPageControls";

const BlogListingPage = ({ pageState, setPageState }) => {

  const [blogs, setBlogs] = useState([]);

  const [selectedShop, setSelectedShop] = useState(undefined);

  const [fields, setFields] = useState([]);
  const [pagination, setPagination] = useState({});

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search"));
  const [latestSearchQuery, setLatestSearchQuery] = useState(
    searchParams.get("search")
  );

  const [perPage, setPerPage] = useState(10);

  const apiCall = [
    fetch_blogs,
    { page: 0, search: search ? search : "", perPage: perPage },
  ];

  const handleDelete = (id) => {
    notify_promise(
      new Promise((resolve, reject) => {
        delete_blog([id])
          .then((res) => {
            setBlogs((prevPets) => {
              return prevPets.filter((p) => {
                return p.id !== id;
              });
            });
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  useEffect(() => {
    updateTableInfo(1);
  }, [latestSearchQuery, perPage]);

  useEffect(() => {
    setSearch(searchParams.get("search"));
  }, [searchParams.get("search")]);

  useEffect(() => {
    setLatestSearchQuery(search);
  }, [search]);

  const updateTableInfo = getTableUpdateCallback({
    apiCall: apiCall,
    dataSetter: setBlogs,
    paginationSetter: setPagination,
    fieldSetter: setFields,
    buttons: [
      {
        template: "edit",
        getRedirectURL: (id) => {
          return "/admin/blogs/edit/:id";
        },
      },
      {
        template: "delete",
        onClick: (id) => {
          handleDelete(id);
        },
      },
    ],
  });

  return (
    <div className="page-content py-3 px-4 bg-lightgray">
      <ShopPopup shop={selectedShop} />

      <ListingPageControls
        type="blog"
        resultType="blog"
        addOnly={true}
        setSearch={setSearchParams}
        noExport={true}
        addNewLink="/admin/blogs/add"
      />

      <ItemListingTable
        fields={fields}
        data={blogs}
        pagination={pagination}
        updateTableInfo={updateTableInfo}
        setPerPage={setPerPage}
        perPage={perPage}
      />
    </div>
  );
};

export default BlogListingPage;
