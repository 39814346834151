import React, { useState } from "react";

const Gallery = ({ images }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  return (
    <div>
      <div className="row">
        {images.map((image, index) => (
          <div className="col-md-auto mb-3" key={image.id}>
            <img
              src={image.image}
              className="img-fluid rounded gallery-images"
              alt={`Image ${index + 1}`}
              onClick={() => handleImageClick(index)}
              data-bs-toggle="modal"
              data-bs-target="#imageModal"
            />
          </div>
        ))}
      </div>

      <div
        className="modal fade"
        id="imageModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div
                id="carouselExampleControls"
                className="carousel slide"
                data-ride="carousel"
              >
                <div className="carousel-inner">
                  {images.map((image, index) => (
                    <div
                      className={`carousel-item ${
                        index === selectedImageIndex ? "active" : ""
                      }`}
                      key={image.id}
                    >
                      <img
                        src={image.image}
                        className="d-block w-100"
                        alt={`Image ${index + 1}`}
                      />
                    </div>
                  ))}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
