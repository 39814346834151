import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";

import DashboardHeader from "./dashboardHeader";
import DashboardSideBar from "./dashboardSideBar";
import ProductFormPage from "./shop/products/ProductFormPage";
import ProductViewPage from "./shop/products/ProductViewPage";
import ProductListingPage from "./shop/products/ProductListingPage";
import OrderListingPage from "./shop/orders/OrderListingPage";
import PetListingPage from "./shelter/pets/PetListingPage";
import PetFormPage from "./shelter/pets/PetFormPage";
import DashboardMain from "./DashboardMain";
import StatsChartViewPage from "./StatsChartViewPage";
import Profile from "./Profile";
import AccountListingPage from "./AccountListingPage";
import AdoptionListingPage from "./shelter/adoptions/AdoptionListingPage";
import PetViewPage from "./shelter/pets/PetViewPage";
import ShopSupportPage from "./shelter/ShopSupportPage";
import AdminSupportPage from "./admin/AdminSupportPage";
import PetTransferListingPage from "./shelter/PetTransferListingPage";
import ShopListingPage from "./admin/ShopListingPage";
import UserListingPage from "./admin/UserListingPage";
import BlogListingPage from "./admin/BlogListingPage";
import DocumentListingPage from "./admin/DocumentListingPage";
import BlogFormPage from "./admin/BlogFormPage";
import DocumentFormPage from "./admin/DocumentFormPage";
import ReportsPage from "./admin/ReportsPage";
import ProductCategoryListingPage from "./admin/ProductCategoryListingPage";
import ProductCategoryFormPage from "./admin/ProductCategoryFormPage";
import AdminServiceCategoryListingPage from "./admin/AdminServiceCategoryListingPage";
import StylesListingPage from "./admin/StylesListingPage";
import StylesFormPage from "./admin/StylesFormPage";
import ServiceCategoryFormPage from "./admin/ServiceCategoryFormPage";
import HomeBannersListingPage from "./admin/HomeBannersListingPage";
import HomeBannersFormPage from "./admin/HomeBannersFormPage";

import ServiceCategoryListingPage from "./clinic/services/ServiceCategoryListingPage";
import ServiceFormPage from "./clinic/services/ServiceFormPage";
import ServiceListingPage from "./clinic/services/ServiceListingPage";
import BookingListingPage from "./clinic/BookingListingPage";
import EmployeeListingPage from "./clinic/EmployeeListingPage";
import EmployeeFormPage from "./clinic/EmployeeFormPage";
import AppointmentFormPage from "./clinic/AppointmentFormPage";
import MyCalendar from "./clinic/Calender";
import SettingsPage from "./common/SettingsPage";
import AccountManagerForm from "./shop/settings/accountManagerForm";

const Home = (props) => {
  const userRole = localStorage.getItem("user_role");

  return (
    <section className="page-section home bg-lightgray position-relative">
      <div className="d-flex flex-grow-1 flex-shrink-1">
        <DashboardSideBar />
        <div
          className={"d-flex flex-column flex-grow-1 page-content-container"}
        >
          <DashboardHeader />
          <PageRoutes userRole={userRole} />
        </div>
      </div>
    </section>
  );
};

export default Home;


const ROLE_ADMIN = "super-admin"
const ROLE_SHOP = "vendor-shop"
const ROLE_SHELTER = "vendor-shelter"
const ROLE_CLINIC = "vendor-clinic"


// Define your routes as a map
const routesMap = {
    "common": {
        "/": DashboardMain,
        "/chart/:stat": StatsChartViewPage,
    },
    [ROLE_ADMIN]: {
        "/sellers/list/:shopStatus?": ShopListingPage,
        "/users/list": UserListingPage,
        "/blogs/list": BlogListingPage,
        "/blogs/add": BlogFormPage,
        "/blogs/edit/:id": BlogFormPage,
        "/documents/list": DocumentListingPage,
        "/documents/add": DocumentFormPage,
        "/documents/edit/:id": DocumentFormPage,
        "/product-categories/list": ProductCategoryListingPage,
        "/product-categories/add": ProductCategoryFormPage,
        "/product-categories/edit/:id": ProductCategoryFormPage,
        "/service-categories/list": AdminServiceCategoryListingPage,
        "/service-categories/add": ServiceCategoryFormPage,
        "/service-categories/edit/:id": ServiceCategoryFormPage,
        "/element-styles/list": StylesListingPage,
        "/element-styles/add": StylesFormPage,
        "/element-styles/edit/:id": StylesFormPage,
        "/home-banners/list": HomeBannersListingPage,
        "/home-banners/add": HomeBannersFormPage,
        "/home-banners/edit/:id": HomeBannersFormPage,
        "/account-manager": AccountListingPage,
        "/accounts/add": AccountManagerForm,
        "/accounts/edit/:id": AccountManagerForm,
        "/support": AdminSupportPage,
        "/reports": ReportsPage,
    },
    [ROLE_SHOP]: {
        "/products/list": ProductListingPage,
        "/products/add": ProductFormPage,
        "/products/edit/:id": ProductFormPage,
        "/products/view/:id": ProductViewPage,
        "/orders": OrderListingPage,
        "/settings/:tabName?": SettingsPage,
        "/support": ShopSupportPage,
        "/account-manager": AccountListingPage,
        "/accounts/add": AccountManagerForm,
        "/accounts/edit/:id": AccountManagerForm,
    },
    [ROLE_SHELTER]: {
        "/pets/list/:petType?": PetListingPage,
        "/pets/view/:id": PetViewPage,
        "/pets/add": PetFormPage,
        "/pets/edit/:id": PetFormPage,
        "/adoptions/list": AdoptionListingPage,
        "/profile": Profile,
        "/transfers/:transferState?": PetTransferListingPage,
        "/settings/:tabName?": SettingsPage,
        "/support": ShopSupportPage,
        "/account-manager": AccountListingPage,
        "/accounts/add": AccountManagerForm,
        "/accounts/edit/:id": AccountManagerForm,
    },
    [ROLE_CLINIC]: {
        "/calendar": MyCalendar,
        "/services/appointments/:bookingStatus?": BookingListingPage,
        "/employees": EmployeeListingPage,
        "/employees/add": EmployeeFormPage,
        "/employees/edit/:id": EmployeeFormPage,
        "/services/categories": ServiceCategoryListingPage,
        "/services/add": ServiceFormPage,
        "/services/edit/:id": ServiceFormPage,
        "/services/byCategory/:parentCategoryID": ServiceListingPage,
        "/appointments/add": AppointmentFormPage,
        "/appointments/edit/:id": AppointmentFormPage,
        "/settings/:tabName?": SettingsPage,
        "/support": ShopSupportPage,
        "/account-manager": AccountListingPage,
        "/accounts/add": AccountManagerForm,
        "/accounts/edit/:id": AccountManagerForm,
    }
};

const PageRoutes = ({ userRole }) => {
    let userRoles = userRole.split(",")
    let routeGroups = Object.entries(routesMap)
    let filteredRoutes = []

    routeGroups.forEach((group) => {
        const [roleKey, routes] = group
        if (roleKey === "common" || userRoles.includes(roleKey)) {
            let routesToAdd = Object.entries(routes)
            routesToAdd.forEach((route) => {
                const [path, component] = route
                filteredRoutes[path] = component
            })
        }
    })

    return (
        <Routes>
            {Object.keys(filteredRoutes).map((path) => {
                return <Route
                    key = {path}
                    path = {path}
                    element={React.createElement(filteredRoutes[path])}
                    />
            })}
        </Routes>
    );
};