import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ListingPageControls from "../common/ListingPageControls";
import { fetch_slots } from "../../../services/clinic/appointments";
import BookingPopup from "./bookings/BookingPopup";

const localizer = momentLocalizer(moment);

const MyCalendar = () => {
  const [events, setEvents] = useState([]);
  const [data, setData] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(undefined);

  const openModal = (index) => {
    setSelectedSlot(data[index]);
    const popup = document.getElementById("bookingPopup");
    popup.style.display = "flex";
  };

  useEffect(() => {
    fetch_slots({ status: "1" }).then((response) => {
      setData(response.data);
      const transformedEvents = response.data.map((slot, index) => {
        const start = moment(
          `${slot.booking_date}T${slot.booking_time}`
        ).toDate();
        const end = moment(start).add(slot.duration, "seconds").toDate();

        return {
          start,
          end,
          title: slot.title,
          index: index,
        };
      });
      setEvents(transformedEvents);
    });
  }, []);

  const handleEventClick = (event) => {
    openModal(event.index);
  };

  return (
    <div className="page-content py-3 px-4 bg-lightgray">
      <BookingPopup
        booking={selectedSlot}
        setBookings={setData}
        setSelectedBooking={setSelectedSlot}
      />
      <ListingPageControls
        type="appointment"
        isCalendar={true}
        addNewLink="/admin/appointments/add"
      />
      <div className={"calender-view full-width"}>
        <div className="bg-white p-5 rounded-3" style={{ height: 900 }}>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={handleEventClick}
          />
        </div>
      </div>
    </div>
  );
};

export default MyCalendar;
