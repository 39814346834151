import React, { useEffect, useState } from "react";
import {
  fetch_service_categories,
  export_service_categories,
} from "../../../../services/clinic/categories";
import { Link } from "react-router-dom";


import { notify_promise } from "../../../../services/utils/toasts";
import ListingCards from "../../common/ListingCards";

const ServiceCategoryListingPage = ({ pageState, setPageState }) => {
  const [searchValue, setSearchvalue] = useState("");
  const [categories, setCategories] = useState([]);

  const getCategories = (page) => {
    notify_promise(
      new Promise((resolve, reject) => {
        fetch_service_categories(page)
          .then((res) => {
            if (res.status === "error") {
              console.log(res);
              reject(res);
              return;
            }

            setCategories(res.data);

            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  useEffect(() => {
    getCategories(1);
  }, []);


  return (
    <div className="page-content py-3 px-4 bg-lightgray">
      <div className="no-print d-flex flex-xl-row flex-column justify-content-between align-items-center mb-4 max-height-btn">
        <div className="d-flex flex-row control-container gap-2">
          <Link to={"/admin/services/add"} className={"btn btn-primary"}>
            + Add New Service
          </Link>
        </div>
      </div>
      <ListingCards list={categories} />
    </div>
  );
};

export default ServiceCategoryListingPage;
