import React from 'react';

const FailSignup = (props) => {
    return (
        <div className="center-content">
            <div className={`${props.class} text-center px-md-5 px-2`}>
                <div className='px-lg-5 px-0'>
                    <h2 className="mb-1 fw-bold text-center">Your documents aren't successfully uploaded ! </h2>
                    <p className="form-help text-center">An error occured please go back and update your info</p>
                    <button className="btn btn-warning py-2 mt-4 fw-bold w-100" onClick={(e) => {
                            e.preventDefault()
                            props.updateTimeLine(4, 15)
                        }}>
                            Go Back
                        </button>
                </div>
            </div>
        </div>
    );
}

export default FailSignup;
