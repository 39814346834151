import React from "react";
import NewIcon from "../../../resources/themes/dashboard-v1/icons/new.svg";
import {Link} from "react-router-dom";

const UserEntry = ({ entry }) => {
  const { id, picture, full_name, created_at } = entry;

  const createdAtFormatted = new Date(created_at).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  return (
    <Link to={"/admin/users/list"}>
      <div className="col-12 d-flex align-items-center adoption-entry py-2">
        <div className="col-3">
          <img src={picture} alt="" className="pic" />
        </div>
        <div className="col-6">
          <div className="link">{full_name}</div>
          <p className="id">User ID: #{id}</p>
          <p className="date">{createdAtFormatted}</p>
        </div>
        <div className="col-3 d-flex align-items-center justify-content-center p-0">
          <img src={NewIcon} alt="" />
        </div>
      </div>
    </Link>
  );
};

export default UserEntry;
