import React, { useEffect } from "react";
import { useState } from "react";
import {
  add_appointments,
  edit_appointments,
  fetch_appointment_create_form,
  fetch_appointment_edit_form,
} from "../../../services/clinic/appointments";
import { Link, Navigate, useParams } from "react-router-dom";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";

const AppointmentFormPage = () => {
  const { id } = useParams();
  const [shouldRedirectToIndex, setShouldRedirectToIndex] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    note: "",
    pet_type: "",
    duration_value: "",
    duration_unit: "minutes",
    customer_name: "",
    phone_number_cc: "",
    phone_number: "",
    pet_name: "",
    booking_date: "",
    booking_time: "",
  });

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    let formDepCallback = () => {
      return fetch_appointment_create_form();
    };
    if (id !== undefined) {
      formDepCallback = () => {
        return fetch_appointment_edit_form(id);
      };
    }

    formDepCallback().then((res) => {
      if (res.status === "ok") {
        if (res.data) {
          setFormData(res.data);
        }
      }
    });
  }, []);

  const handleTextInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    if (validationErrors[name]) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  const handlePhoneNumberChange = (value) => {
    if (typeof value === "string") {
      const digitCount = (value.match(/\d/g) || []).length;
      if (digitCount > 1) {
        const parsedPhoneNumber = parsePhoneNumber(value);
        if (parsedPhoneNumber) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            phone_number: value,
          }));
          const phoneNumberCc = parsedPhoneNumber.countryCallingCode;
          setFormData((prevFormData) => ({
            ...prevFormData,
            phone_number_cc: phoneNumberCc,
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            phone_number: "",
          }));
          setFormData((prevFormData) => ({
            ...prevFormData,
            phone_number_cc: "",
          }));
        }
      } else {
        setFormData((prevFormData) => ({ ...prevFormData, phone_number: "" }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          phone_number_cc: "",
        }));
      }
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, phone_number: "" }));
      setFormData((prevFormData) => ({ ...prevFormData, phone_number_cc: "" }));
    }
  };

  const handleSave = () => {
    setValidationErrors({});
    if (id !== undefined) {
      edit_appointments(id, formData).then((res) => {
        if (res.status === "ok") {
          setShouldRedirectToIndex(true);
        } else if (res.type === "validation") {
          setValidationErrors(res.validations);
        }
      });
    } else {
      add_appointments(formData).then((res) => {
        if (res.status === "ok") {
          setShouldRedirectToIndex(true);
        } else if (res.type === "validation") {
          setValidationErrors(res.validations);
        }
      });
    }
  };

  if (shouldRedirectToIndex) {
    return <Navigate to={"/admin/calendar"} />;
  }

  return (
    <div className="page-content products py-3 px-4 flex-grow-1 flex-shrink-1 bg-lightgray">
      <div className="mb-4">
        <span className="fw-bold dashboard-title">
          {id !== undefined ? "Edit Appointment" : "Add Appointment"}
        </span>
        <div className="breadcrumbs path fw-semibold gap-2 d-flex flex-row">
          <Link className={"link"} to={"/admin/"}>
            Dashboard
          </Link>
          <span>&gt;</span>
          <Link className={"link"} to={"/admin/calendar"}>
            Calendar
          </Link>
          <span>&gt;</span>
          <span>{id !== undefined ? formData.title : "New Appointment"}</span>
        </div>
      </div>

      <div className="inputs-container bg-white">
        {Object.keys(validationErrors).length > 0 && (
          <div className="alert alert-danger">
            Please address the errors highlighted below.
          </div>
        )}
        <div className="product-info d-flex flex-column gap-4">
          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Appointment Title</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleTextInputChange}
                placeholder={"EX: Full Haircut"}
              />
              {validationErrors.title && (
                <span className="error-text text-danger">
                  {validationErrors.title[0]}
                </span>
              )}
            </div>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Customer Name</label>
              <input
                type="text"
                name="customer_name"
                value={formData.customer_name}
                onChange={handleTextInputChange}
                placeholder={"name..."}
              />
              {validationErrors.customer_name && (
                <span className="error-text text-danger">
                  {validationErrors.customer_name[0]}
                </span>
              )}
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Customer Phone Number</label>
              <PhoneInput
                defaultCountry="AE"
                international
                withCountryCallingCode
                value={formData.phone_number}
                onChange={handlePhoneNumberChange}
              />
              {validationErrors.phone_number && (
                <span className="error-text text-danger">
                  {validationErrors.phone_number[0]}
                </span>
              )}
            </div>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Pet Name</label>
              <input
                type="text"
                name="pet_name"
                value={formData.pet_name}
                onChange={handleTextInputChange}
                placeholder={"pet name..."}
              />
              {validationErrors.pet_name && (
                <span className="error-text text-danger">
                  {validationErrors.pet_name[0]}
                </span>
              )}
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Desired Date</label>
              <input
                type="date"
                name="booking_date"
                value={formData.booking_date}
                onChange={handleTextInputChange}
              />
              {validationErrors.booking_date && (
                <span className="error-text text-danger">
                  {validationErrors.booking_date[0]}
                </span>
              )}
            </div>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Available Hour</label>
              <input
                type="time"
                name="booking_time"
                value={formData.booking_time}
                onChange={handleTextInputChange}
              />
              {validationErrors.booking_time && (
                <span className="error-text text-danger">
                  {validationErrors.booking_time[0]}
                </span>
              )}
            </div>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Duration</label>
              <div className="row w-100">
                <div className="col-sm-8 col-7 pe-0">
                  <input
                    type="number"
                    className="form-control w-100 duration-input"
                    placeholder="EX: 2"
                    value={formData.duration_value}
                    name="duration_value"
                    onChange={handleTextInputChange}
                  />
                </div>
                <div className="col-sm-4 col-5 ps-0">
                  <select
                    name="duration_unit"
                    class="form-select form-select-lg h-100 duration-select"
                    value={formData.duration_unit}
                    onChange={handleTextInputChange}
                  >
                    <option selected value="minutes">
                      Minutes
                    </option>
                    <option value="hours">Hours</option>
                  </select>
                </div>
              </div>
              {validationErrors.duration && (
                <span className="error-text text-danger">
                  {validationErrors.duration[0]}
                </span>
              )}
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Other Details</label>
              <textarea
                name="note"
                onChange={handleTextInputChange}
                className="w-100"
                placeholder="Write details..."
              />
            </div>
          </div>
        </div>

        {Object.keys(validationErrors).length > 0 && (
          <div className="alert alert-danger mt-3">
            Please address the errors highlighted.
          </div>
        )}

        <div className="d-flex flex-md-row flex-column justify-content-md-end align-items-center gap-3 btn-containers">
          <button className={"btn btn-primary px-5 my-4"} onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppointmentFormPage;
