const API_HOST =
  process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/shelter/adoptions/";
const EXPORT_API =
  process.env.REACT_APP_DASHBOARD_API_URL +
  "/api/dash/shelter/export/adoptions/";

export const fetch_shop_adoptions = (options = {}) => {
  const { page = 1, statusFilter, pet_id, request_id, filter } = options;

  const queryParams = { page };

  if (statusFilter !== undefined) {
    queryParams["status"] = statusFilter;
  }

  if (filter !== undefined) {
    queryParams["status"] = options["filter"];
  }

  if (pet_id !== undefined) {
    queryParams["pet_id"] = pet_id;
  }

  if (request_id !== undefined) {
    queryParams["request_id"] = request_id;
  }

  queryParams["perPage"] = localStorage.getItem("per_page") ?? 10;

  const encodedQueryParams = new URLSearchParams(queryParams);

  return fetch(`${API_HOST}?${encodedQueryParams}`, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const fetch_shop_adoption = (id) => {
  return fetch(API_HOST + id, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const export_shop_adoptions = () => {
  return fetch(EXPORT_API, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const add_shop_adoptions = (data) => {
  return fetch(API_HOST, {
    method: "POST",
    credentials: "include",
    secure: true,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const edit_shop_adoptions = (id, data) => {
  return fetch(`${API_HOST}${id}`, {
    method: "PUT",
    credentials: "include",
    secure: true,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const edit_adoptions_status = (id, status) => {
  return fetch(`${API_HOST}${id}/update-status?status=${status}`, {
    method: "PUT",
    credentials: "include",
    secure: true,
    body: JSON.stringify({}),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const delete_shop_adoptions = (ids) => {
  let idCsv = ids.join(",");
  return fetch(`${API_HOST}${idCsv}`, {
    method: "DELETE",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};
