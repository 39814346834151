const API_HOST =
  process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/shelter/vaccines";

export const fetch_vaccines = (pet_id) => {
    const queryParams = { };
    if (pet_id !== undefined) {
      queryParams["pet_id"] = pet_id;
    }
    const encodedQueryParams = new URLSearchParams(queryParams);
  return fetch(`${API_HOST}?${encodedQueryParams}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};
