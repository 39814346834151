import React, { useEffect, useState } from "react";
import {
  fetch_reports,
} from "../../../services/admin/reports";
import ItemListingTable, {
  getTableUpdateCallback,
} from "../common/ItemListingTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import { notify_promise } from "../../../services/utils/toasts";
import ListingPageControls from "../common/ListingPageControls";

const ReportsPage = ({ pageState, setPageState }) => {

  const [reports, setReports] = useState([]);

  const [fields, setFields] = useState([]);
  const [pagination, setPagination] = useState({});

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search"));
  const [latestSearchQuery, setLatestSearchQuery] = useState(
    searchParams.get("search")
  );

  const [perPage, setPerPage] = useState(10);

  const apiCall = [
    fetch_reports,
    { page: 0, search: search ? search : "", perPage: perPage },
  ];

  useEffect(() => {
    updateTableInfo(1);
  }, [latestSearchQuery, perPage]);

  useEffect(() => {
    setSearch(searchParams.get("search"));
  }, [searchParams.get("search")]);

  useEffect(() => {
    setLatestSearchQuery(search);
  }, [search]);

  const updateTableInfo = getTableUpdateCallback({
    apiCall: apiCall,
    dataSetter: setReports,
    paginationSetter: setPagination,
    fieldSetter: setFields,
    buttons: [
      {
      },
    ],
  });

  return (
    <div className="page-content py-3 px-4 bg-lightgray">
      <ListingPageControls
        type="report"
        resultType="Report"
        addOnly={true}
        setSearch={setSearchParams}
        hasSingleTitle={true}
      />

      <ItemListingTable
        fields={fields}
        data={reports}
        pagination={pagination}
        updateTableInfo={updateTableInfo}
        setPerPage={setPerPage}
        perPage={perPage}
      />
    </div>
  );
};

export default ReportsPage;
