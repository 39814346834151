import React, { useState, useEffect } from "react";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import verified from "../../../../resources/themes/dashboard-v1/icons/verified.svg";
import {
  add_user,
  edit_user,
  fetch_account_create_form,
  fetch_account_edit_form,
} from "../../../../services/accounts.js";
import { notify_promise } from "../../../../services/utils/toasts";
import { notify_error } from "../../../../services/utils/toasts";
import { Link, Navigate, useParams } from "react-router-dom";
import {notify} from "react-big-calendar/lib/utils/helpers";

const defaultFormValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  password: "",
  new_password: "",
  new_password_confirmation: "",
}

const AccountManagerForm = () => {
  const { id } = useParams();
  const [shouldRedirectToIndex, setShouldRedirectToIndex] = useState(false);

  const [formData, setFormData] = useState({
    ...defaultFormValues,
    loading: true
  });

  // Get Form old data if this is an Edit request
  useEffect(() => {
    if (id === undefined) {
      setFormData({...formData, loading: false})
      return;
    }
    fetch_account_create_form(id).then((res) => {
      if (res.status !== "ok") {
        return;
      }
      setFormData(() => ({
        first_name: res.data.first_name,
        last_name: res.data.last_name,
        email: res.data.email,
        password: res.data.password,
        phone_number_cc: res.data.phone_number_cc,
        phone_number: res.data.phone_number,
        new_password: "",
        new_password_confirmation: "",
        loading: false
      }));
    });
  }, []);

  const [errors, setErrors] = useState(defaultFormValues);

  const handleTextInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handlePhoneNumberChange = (value) => {
    if (typeof value === "string") {
      const digitCount = (value.match(/\d/g) || []).length;
      if (digitCount > 1) {
        const parsedPhoneNumber = parsePhoneNumber(value);
        if (parsedPhoneNumber) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            phone_number: value,
          }));
          const phoneNumberCc = parsedPhoneNumber.countryCallingCode;
          setFormData((prevFormData) => ({
            ...prevFormData,
            phone_number_cc: phoneNumberCc,
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            phone_number: "",
          }));
          setFormData((prevFormData) => ({
            ...prevFormData,
            phone_number_cc: "",
          }));
        }
      } else {
        setFormData((prevFormData) => ({ ...prevFormData, phone_number: "" }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          phone_number_cc: "",
        }));
      }
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, phone_number: "" }));
      setFormData((prevFormData) => ({ ...prevFormData, phone_number_cc: "" }));
    }
  };

  const handleUserRegistration = () => {
    if (!validateFields(formData, setErrors)) return;

    const action = id ? edit_user(id, formData) : add_user(formData);
    const errorMessage = id ? "Can't Edit" : "Can't Add";

    notify_promise(
        action
            .then((res) => {
              if (res.status === "ok") {
                setShouldRedirectToIndex(true);
              }

              if (res.status === "error" && res.validations !== undefined) {
                for (const [key, value] of Object.entries(res.validations)) {
                  const firstErrorMessage = value[0]
                  setErrors((prevErrorData) => ({ ...prevErrorData, [key]: firstErrorMessage }));
                }
              }
              return res;
            })
            .catch((e) => {
              notify_error(errorMessage)
              console.log(e)
            })
    );
  };
  if (shouldRedirectToIndex) {
    return <Navigate to={"/admin/account-manager"} />;
  }

  if (formData.loading) {
    return (
      <div className="d-flex p-5 align-items-center justify-content-center">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="page-content products py-3 px-4 flex-grow-1 flex-shrink-1 bg-lightgray">
      <div className="mb-4">
        <span className="fw-bold dashboard-title">
          {id !== undefined ? "Edit Account" : "Add Account"}
        </span>
        <div className="breadcrumbs path fw-semibold gap-2 d-flex flex-row">
          <Link className={"link"} to={"/admin/"}>
            Dashboard
          </Link>
          <span>&gt;</span>
          <Link className={"link"} to={"/admin/account-manager"}>
            Accounts
          </Link>
          <span>&gt;</span>
          <span>{id !== undefined ? "Edit" : "New Account"}</span>
        </div>
      </div>
      <div className="inputs-container bg-white mb-2">
        <div className="product-info d-flex flex-column gap-4">
          <span className="product-info-title">ADD NEW USER</span>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container">
              <label className="fw-semibold">First Name</label>
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleTextInputChange}
                placeholder={"First Name"}
              />
              {errors.first_name && (
                <div className="text-danger mt-2 w-75">
                  {errors.first_name}
                </div>
              )}
            </div>

            <div className="d-flex flex-column gap-1 input-container">
              <label className="fw-semibold">Last Name</label>
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleTextInputChange}
                placeholder={"Last Name"}
              />
              {errors.last_name && (
                <div className="text-danger mt-2 w-75">
                  {errors.last_name}
                </div>
              )}
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container">
              <label className="fw-semibold">Email Address</label>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleTextInputChange}
                placeholder={"Email Address"}
              />
              {errors.email && (
                <div className="text-danger mt-2 w-75">
                  {errors.email}
                </div>
              )}
            </div>
            <div className="d-flex flex-column gap-1 input-container">
              <label className="fw-semibold">
                Phone Number <img src={verified} alt="verified" />
              </label>
              <PhoneInput
                defaultCountry="AE"
                international
                withCountryCallingCode
                value={formData.phone_number}
                onChange={handlePhoneNumberChange}
              />
              {errors.phone_number && (
                <div className="text-danger mt-2 w-75">
                  {errors.phone_number}
                </div>
              )}
            </div>
          </div>
          {id !== undefined ? (
            ""
          ) : (
            <div className="d-flex flex-md-row flex-column">
              <div className="d-flex flex-column gap-1 input-container">
                <label className="fw-semibold">Password</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleTextInputChange}
                  placeholder={"Password"}
                />
                {errors.password && (
                  <div className="text-danger mt-2 w-75">
                    {errors.password}
                  </div>
                )}
              </div>
              <div className="d-flex flex-column gap-1 input-container"></div>
            </div>
          )}
        </div>
      </div>
      {/*{id !== undefined ? (*/}
      {/*  <div className="inputs-container bg-white mb-2">*/}
      {/*    <div className="product-info form row">*/}
      {/*      <span className="product-info-title mb-4">CHANGE PASSWORD</span>*/}
      {/*      <div className="col-md-6 mb-4">*/}
      {/*        <div className="">*/}
      {/*          <label className="fw-semibold">Old Password</label>*/}
      {/*          <input*/}
      {/*            type="password"*/}
      {/*            name="password"*/}
      {/*            placeholder="Old Password"*/}
      {/*            onChange={handleTextInputChange}*/}
      {/*          />*/}
      {/*          {errors.password && (*/}
      {/*            <div className="text-danger mt-2 w-75">*/}
      {/*              {errors.password}*/}
      {/*            </div>*/}
      {/*          )}*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="col-md-6 mb-4">*/}
      {/*        <div className="">*/}
      {/*          <label className="fw-semibold">New Password</label>*/}
      {/*          <input*/}
      {/*            type="password"*/}
      {/*            name="new_password"*/}
      {/*            placeholder="New Password"*/}
      {/*            onChange={handleTextInputChange}*/}
      {/*          />*/}
      {/*          {errors.new_password && (*/}
      {/*            <div className="text-danger mt-2 w-75">*/}
      {/*              {errors.new_password}*/}
      {/*            </div>*/}
      {/*          )}*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="col-md-6 mb-2">*/}
      {/*        <div className="">*/}
      {/*          <label className="fw-semibold">Confirm Password</label>*/}
      {/*          <input*/}
      {/*            type="password"*/}
      {/*            name="new_password_confirmation"*/}
      {/*            placeholder="Confirm Password"*/}
      {/*            onChange={handleTextInputChange}*/}
      {/*          />*/}
      {/*          {errors.new_password_confirmation && (*/}
      {/*            <div className="text-danger mt-2 w-75">*/}
      {/*              {errors.new_password_confirmation}*/}
      {/*            </div>*/}
      {/*          )}*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*) : (*/}
      {/*  ""*/}
      {/*)}*/}
      <div className="d-flex flex-md-row flex-column justify-content-md-end align-items-center gap-3 btn-containers py-4 px-4">
        <button
          className={"btn btn-primary pl-5 pr-5"}
          onClick={handleUserRegistration}
        >
          {" "}
          {id !== undefined ? "Edit" : "Add"} User{" "}
        </button>
      </div>
    </div>
  );
};

export default AccountManagerForm;


const validateFields = (formData, setErrors) => {
  let isValid = true;
  setErrors({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    new_password: "",
    new_password_confirmation: "",
  });

  if (formData.first_name.trim() === "") {
    setErrors((prevErrors) => ({
      ...prevErrors,
      first_name: "first name is required.",
    }));
    isValid = false;
  }
  if (formData.last_name.trim() === "") {
    setErrors((prevErrors) => ({
      ...prevErrors,
      last_name: "last name is required.",
    }));
    isValid = false;
  }

  if (formData.password.trim() === "") {
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: "Password is required.",
    }));
    isValid = false;
  }

  if (formData.email.trim() === "") {
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: "Email is required.",
    }));
    isValid = false;
  }
  if (formData.email !== "" && !/\S+@\S+\.\S+/.test(formData.email)) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: "Please enter a valid email format",
    }));
    isValid = false;
  }

  if (formData.phone_number.trim() === "") {
    setErrors((prevErrors) => ({
      ...prevErrors,
      phone_number: "Phone number is required.",
    }));
    isValid = false;
  }

  if (
      formData.new_password && formData.new_password !== "" &&
      formData.new_password.length < 8
  ) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      new_password: "Password should be at least 8 characters long",
    }));
    isValid = false;
  }

  if (
      formData.new_password !== "" && formData.new_password_confirmation &&
      formData.new_password_confirmation.trim() === ""
  ) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      new_password_confirmation: "Please confirm your new password",
    }));
    isValid = false;
  }

  if (
      formData.new_password_confirmation !== "" &&
      formData.new_password !== formData.new_password_confirmation
  ) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      new_password_confirmation: "Passwords doesn't match",
    }));
    isValid = false;
  }

  return isValid;
};
