import React, { useEffect, useState } from "react";
import iconClose from "../../../../resources/themes/dashboard-v1/icons/close.svg";

import inStock from "../../../../resources/themes/dashboard-v1/icons/inStock.svg";
import outStock from "../../../../resources/themes/dashboard-v1/icons/outStock.svg";

import {
  chatMessageIcon,
  circleXIcon,
  dropdownCaret,
  locationIcon,
  phoneIcon,
} from "../../../../resources/themes/dashboard-v1/icons/icons";

import { change_timing } from "../../../../services/clinic/bookings";

import { notify_promise } from "../../../../services/utils/toasts";

const BookingPopup = ({ booking }) => {
  const [showInputSection, setShowInputSection] = useState(false);
  const [suggestedTimes, setSuggestedTimes] = useState([
    { date: "", time: "" },
  ]);

  if (booking === undefined) {
    return (
      <div
        id="bookingPopup"
        className="popup-container vw-100 vh-100  jusitfy-content-center align-items-center p-md-0 p-2"
      ></div>
    );
  }

  const changeTiming = () => {
    const newTimings = suggestedTimes.map(
      ({ date, time }) => `${date} ${time}`
    );

    const data = {
      new_timings: newTimings,
    };
    
    notify_promise(
      new Promise((resolve, reject) => {
        change_timing(booking.id, JSON.stringify(data))
          .then((res) => {
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  const closePopup = () => {
    const popup = document.getElementById("bookingPopup");
    popup.style.display = "none";
  };

  window.onkeydown = function (event) {
    if (event.keyCode === 27) {
      closePopup();
    }
  };

  const handleSuggestNewTime = () => {
    setSuggestedTimes([{ date: "", time: "" }]);
    setShowInputSection(true);
  };

  const handleAddTime = () => {
    setSuggestedTimes([...suggestedTimes, { date: "", time: "" }]);
  };

  const handleDateTimeChange = (index, field, value) => {
    const updatedTimes = [...suggestedTimes];
    updatedTimes[index][field] = value;
    setSuggestedTimes(updatedTimes);
  };

  const handleRemoveTime = (index) => {
    const updatedTimes = [...suggestedTimes];
    updatedTimes.splice(index, 1);
    setSuggestedTimes(updatedTimes);
  };

  const handleSaveTimes = () => {
    changeTiming();
    setShowInputSection(false);
  };

  return (
    <div
      id="bookingPopup"
      className="popup-container vw-100 vh-100  jusitfy-content-center align-items-center p-md-0 p-2"
    >
      <div
        onClick={closePopup}
        className="w-100 h-100 position-absolute outer-modal top-0 left-0"
      ></div>
      {booking.pet_owner_id ? (
        <div className="popup position-relative bg-white">
          <img
            onClick={closePopup}
            className="close-btn"
            src={iconClose}
            alt="close"
          />

          <div className="py-3 px-4">
            <div className="pb-0">
              <div className="d-flex justify-content-between">
                <h5 className="modal-title" id="chatModalLabel">
                  Appointment &nbsp;
                  <span className="fs-6 text-muted">#{booking.id}</span>
                </h5>
                {booking.status === 1 ? (
                  <span className="badge text-bg-success fs-6 rounded-3">
                    Accepted
                  </span>
                ) : booking.status === 2 ? (
                  <span className="badge text-bg-info fs-6 rounded-3">
                    Fulfilled
                  </span>
                ) : booking.status === 3 ? (
                  <span className="badge text-bg-danger fs-6 rounded-3">
                    Refused
                  </span>
                ) : (
                  <span className="badge text-bg-warning fs-6 rounded-3 text-light">
                    Pending
                  </span>
                )}
              </div>

              <div className="product-modal-header-info px-0 pt-4">
                <div className="row">
                  <div className="col-12 mb-4">
                    <h5>{booking.title}</h5>
                    <h6>
                      <span className="fw-semibold">Date:</span>
                      {booking.booking_date} at {booking.booking_time}
                    </h6>
                    <p>
                      <span className="fw-semibold">Services: </span>
                      {booking.service_name}
                    </p>
                    <p>
                      <span className="fw-semibold">
                        Appointment Description:{" "}
                      </span>
                      {booking.note}
                    </p>
                  </div>
                  <div className="col-6 border-end">
                    <h6 className="fw-bold">Pet Owner Info</h6>
                    <div className={"text-center"}>
                      <img
                        src={booking.customer.picture}
                        height={100}
                        className="rounded-circle mb-3"
                        alt="User Image"
                        width="100"
                      />
                    </div>

                    <p className={"text-center"}>
                      <strong>{booking.customer_name}</strong>
                    </p>

                    <div className="d-flex">
                      {chatMessageIcon}
                      <p className="ms-2 mb-2">{booking.customer.email}</p>
                    </div>
                    <div className="d-flex">
                      {phoneIcon}
                      <p className="ms-2 mb-2">
                        {booking.customer.phone_number}
                      </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <h6 className="fw-bold">Pet Info</h6>
                    <div className={"text-center"}>
                      <img
                        src={booking.pet.image}
                        height={100}
                        className="rounded-circle mb-3"
                        alt="Pet Image"
                        width="100"
                      />
                    </div>

                    <p className={"text-center"}>
                      <strong>{booking.pet_name}</strong>
                    </p>

                    <div className="d-flex">
                      <p className="ms-2 mb-2 pet-modal-desc">
                        {booking.pet.description}
                      </p>
                    </div>
                    <div className="d-flex">
                      <span className="fw-bold ms-2 ">Type: </span>
                      <p className="mb-2"> {booking.pet.type.title}</p>
                    </div>
                  </div>
                  {showInputSection && suggestedTimes.length > 0 ? (
                    <div className="col-12 mt-4">
                      {suggestedTimes.map((time, index) => (
                        <div
                          key={index}
                          className="mb-3 d-flex justify-content-center"
                        >
                          <input
                            type="date"
                            className="form-control w-auto ps-2"
                            value={time.date}
                            onChange={(e) =>
                              handleDateTimeChange(
                                index,
                                "date",
                                e.target.value
                              )
                            }
                          />
                          <input
                            type="time"
                            className="mx-2 form-control w-auto ps-2"
                            value={time.time}
                            onChange={(e) =>
                              handleDateTimeChange(
                                index,
                                "time",
                                e.target.value
                              )
                            }
                          />
                          <button
                            className="btn btn-outline-danger"
                            onClick={() => handleRemoveTime(index)}
                          >
                            X
                          </button>
                        </div>
                      ))}
                      <div className="d-flex justify-content-around">
                        <button
                          className="btn btn-primary"
                          onClick={handleAddTime}
                        >
                          Add Another Time
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={handleSaveTimes}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="col-12 d-flex justify-content-center mt-4">
                      <button
                        className="btn btn-primary"
                        onClick={handleSuggestNewTime}
                      >
                        Suggest New Time
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="popup position-relative bg-white">
          <img
            onClick={closePopup}
            className="close-btn"
            src={iconClose}
            alt="close"
          />
          <div className="py-3 px-4">
            <div className="pb-0">
              <h5 className="modal-title" id="chatModalLabel">
                Appointment
              </h5>

              <div className="product-modal-header-info px-0 pt-4">
                <div className="row">
                  <div className="col-12 mb-4">
                    <h5>{booking.title}</h5>
                    <h6>
                      <span className="fw-semibold">Date:</span>
                      {booking.booking_date} at {booking.booking_time}
                    </h6>
                    <p>
                      <span className="fw-semibold">
                        Appointment Description:{" "}
                      </span>
                      {booking.note}
                    </p>
                    <p>
                      <span className="fw-semibold">Customer Name: </span>
                      {booking.customer_name}
                    </p>
                    <p>
                      <span className="fw-semibold">Customer Phone Number: </span>
                      {booking.phone_number}
                    </p>
                    <p>
                      <span className="fw-semibold">Pet Name: </span>
                      {booking.pet_name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingPopup;
