import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  fetch_transfers,
  approve_transfer,
  reject_transfer,
  cancel_transfer,
} from "../../../services/shelters/transfers";
import ItemListingTable, {
  getTableUpdateCallback,
} from "../common/ItemListingTable";
import ListingPageControls from "../common/ListingPageControls";
import { notify_promise } from "../../../services/utils/toasts";

const PetTransferListingPage = () => {
  const [fields, setFields] = useState([]);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filters, setFilters] = useState([]);

  const [activeFilter, setActiveFilter] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search"));
  const [latestSearchQuery, setLatestSearchQuery] = useState(
    searchParams.get("search")
  );

  const navigate = useNavigate();

  let { transferState } = useParams();

  const filterMapping = {
    0: "Incoming",
    1: "Outgoing",
  };

  let numericTransferState = Object.keys(filterMapping).find(
    (key) => filterMapping[key] === transferState
  );

  console.log(numericTransferState);

  if (!numericTransferState) numericTransferState = "0";

  const handleApprove = (id) => {
    notify_promise(
      new Promise((resolve, reject) => {
        approve_transfer(id)
          .then((res) => {
            setData((data) => data.filter((item) => item.id !== id));
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  const handleReject = (id) => {
    notify_promise(
      new Promise((resolve, reject) => {
        reject_transfer(id)
          .then((res) => {
            setData((data) => data.filter((item) => item.id !== id));
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  const handleDelete = (id) => {
    notify_promise(
      new Promise((resolve, reject) => {
        cancel_transfer([id])
          .then((res) => {
            setData((prev) => {
              return prev.filter((p) => {
                return p.id !== id;
              });
            });
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  const apiCall = [
    fetch_transfers,
    { page: 0, type: numericTransferState, search: search ? search : "" },
  ];

  const updateTableInfo = getTableUpdateCallback({
    apiCall: apiCall,
    dataSetter: setData,
    paginationSetter: setPagination,
    fieldSetter: setFields,
    buttons: [],
    setFilters: setFilters,
    setActiveFilter: setActiveFilter,
    filterMapping: filterMapping,
    navigate: navigate,
    numericStatus: numericTransferState,
    type: "transfer",
    handleApprove: handleApprove,
    handleReject: handleReject,
    handleDelete: handleDelete,
  });

  useEffect(() => {
    updateTableInfo(0);
    setSearch(searchParams.get("search"));
    setLatestSearchQuery(search);
  }, [numericTransferState, searchParams.get("search"), latestSearchQuery]);

  return (
    <div className="page-content py-3 px-4 bg-lightgray">
      <ListingPageControls
        type="transfer"
        hasTabs={true}
        resultType="transfer"
        filters={filters}
        setSearch={setSearchParams}
        noExport={true}
      />
      <ItemListingTable
        updateTableInfo={updateTableInfo}
        fields={fields}
        data={data}
        pagination={pagination}
        handleApprove={handleApprove}
        handleReject={handleReject}
        customTDs={{
          service_name: (item) => (
            <ul className={"text-start"}>
              {item["service_names"].map(function (serviceName, index) {
                return (
                  <li className={"mb-1"} key={index}>
                    {serviceName}
                  </li>
                );
              })}
            </ul>
          ),
        }}
      />
    </div>
  );
};

export default PetTransferListingPage;
