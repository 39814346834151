import React from "react";

const BannerPreviewBox = ({ formData }) => {
    const getFileURL = (image) => {
      if (image instanceof File) {
        return URL.createObjectURL(image);
      } else {
        return image;
      }
    };

    const previewStyle = {
      backgroundColor: formData.background_color,
      backgroundImage: `url(${getFileURL(formData.background_image)})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      color: formData.button_text_color,
      textAlign: "center",
      padding: "20px",
      margin: "0",
    };

    const buttonStyle = {
        backgroundColor: formData.button_bg_color,
        color: formData.button_text_color,
        padding: "8px 15px",
        borderRadius: "5px",
        textDecoration: "none",
        marginTop: "50px", 
        display: "inline-block",
    };

    return (
        <>
            <h4 className="fw-bold text-center mb-4">Preview</h4>
            <div className="banner-preview-box" style={previewStyle}>
                <p>{formData.text}</p>

                {formData.button_text && (
                    <a href="#" className="banner-button" style={buttonStyle}>
                    {formData.button_text}
                    </a>
                )}
            </div>
        </>
    );
};

export default BannerPreviewBox;
