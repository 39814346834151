import React, { useEffect } from "react";
import Select from "react-select";
import { useState } from "react";
import {
  add_services,
  edit_services,
  fetch_service,
  fetch_service_create_form,
  fetch_service_edit_form,
} from "../../../../services/clinic/services";
import { Link, Navigate, useParams } from "react-router-dom";
import ImageUploader from "../../common/ImageUploader";
import CustomRadio from "../../common/CustomRadio";
import TextEditor from "../../TextEditor";

let removedImages = [];

const ServiceFormPage = () => {
  const { id } = useParams();
  const [shouldRedirectToIndex, setShouldRedirectToIndex] = useState(false);
  const [selectedProfileIndex, setSelectedProfileIndex] = useState(0);
  const [categories, setCategories] = useState([]);
  const [pets, setPets] = useState([]);
  const [selectedPetTypes, setSelectedPetTypes] = useState([]);

  const placeOptions = [
    { value: "at_customer", label: "At customer premises" },
    { value: "in_store_only", label: "In-store ONLY service" },
    { value: "anywhere", label: "AnyWhere ( Both )" },
  ];

  const [selectedButton, setSelectedButton] = useState(placeOptions[0].value);

  const serviceUnitOptions = [
    { value: "hourly", label: "Hourly" },
    { value: "daily", label: "Daily" },
    { value: "monthly", label: "Monthly" },
    { value: "per_cut", label: "Per Cut" },
    { value: "per_service", label: "Per Service" },
    { value: "per_session", label: "Per Session" },
    { value: "per_visit", label: "Per Visit" },
    { value: "per_hour", label: "Per Hour" },
  ];

  const [formData, setFormData] = useState({
    title: "",
    description1: "",
    description2: "",
    price: "",
    picture: "",
    images: [],
    index: 0,
    category_id: 1,
    status: 0,
    place: selectedButton,
    service_unit: "",
    cancellation_policy: "",
    drop_off_pick_up_policy: "",
    pet_type_ids: [],
  });

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      place: selectedButton,
    }));
  }, [selectedButton]);

  useEffect(() => {
    let formDepCallback = () => {
      return fetch_service_create_form();
    };
    if (id !== undefined) {
      formDepCallback = () => {
        return fetch_service_edit_form(id);
      };
    }
    formDepCallback().then((res) => {
      if (res.status === "ok") {
        setCategories(res.categories);
        setPets(res.petTypes);
        if (res.data) {
          setFormData(res.data);
          setSelectedProfileIndex(res.data.index);
          setSelectedButton(res.data.place);
          if (res.selected_pet_types) {
            setSelectedPetTypes(res.selected_pet_types);
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      fetch_service(id).then((res) => {
        if (res.status === "ok") {
          setFormData({ ...res.data });
          setSelectedProfileIndex(res.data.index);
        }
      });
    }
  }, []);

  const handleTextInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "pet_type_ids") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }

    if (validationErrors[name]) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  const handleSelectProfile = (index) => {
    setSelectedProfileIndex(index);
    setFormData((prevFormData) => ({
      ...prevFormData,
      index: index,
      picture: formData.images[index],
    }));
  };

  useEffect(() => {
    console.log(selectedProfileIndex);
  }, [selectedProfileIndex]);

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    setFormData((prevFormData) => ({
      ...prevFormData,
      images: [...prevFormData.images, ...files],
      index: selectedProfileIndex,
      picture: formData.images[selectedProfileIndex],
    }));
  };

  useEffect(() => {
    console.log(formData);
    for (let i = 0; i < formData.images.length; i++) {
      formData[`images[${i}]`] = formData.images[i];
    }
  }, [formData]);

  const handleRemoveImage = (index) => {
    removedImages.push(formData.images[index].id);
    setFormData((prevFormData) => {
      const newImages = prevFormData.images.slice();
      newImages.splice(index, 1);

      const newIndex =
        index === selectedProfileIndex ? 0 : selectedProfileIndex;

      delete formData[`images[${index}]`];

      return {
        ...prevFormData,
        picture: newImages.length > 0 ? newImages[newIndex] : "",
        images: newImages,
        index: newIndex,
        removedImages: removedImages,
      };
    });
  };

  const handleSelectInputChange = (name, selected) => {
    if (name === "pet_type_ids") {
      const selectedValues = selected.map((pet) => pet.value);
      handleTextInputChange({
        target: {
          name: name,
          value: selectedValues,
        },
      });
      setSelectedPetTypes(selected.map((option) => option.value));
    } else {
      handleTextInputChange({
        target: {
          name: name,
          value: selected.value,
        },
      });
    }
  };

  const handleSave = () => {
    console.log(formData);
    setValidationErrors({});
    if (id !== undefined) {
      edit_services(id, formData).then((res) => {
        if (res.status === "ok") {
          setShouldRedirectToIndex(true);
        } else if (res.type === "validation") {
          setValidationErrors(res.validations);
        }
      });
    } else {
      add_services(formData).then((res) => {
        if (res.status === "ok") {
          setShouldRedirectToIndex(true);
        } else if (res.type === "validation") {
          setValidationErrors(res.validations);
        }
      });
    }
  };

  if (shouldRedirectToIndex) {
    return (
      <Navigate to={"/admin/services/byCategory/" + formData.category_id} />
    );
  }

  return (
    <div className="page-content products py-3 px-4 flex-grow-1 flex-shrink-1 bg-lightgray">
      <div className="mb-4">
        <span className="fw-bold dashboard-title">
          {id !== undefined ? "Edit Service" : "Add Service"}
        </span>
        <div className="breadcrumbs path fw-semibold gap-2 d-flex flex-row">
          <Link className={"link"} to={"/admin/"}>
            Dashboard
          </Link>
          <span>&gt;</span>
          <Link className={"link"} to={"/admin/services/categories"}>
            Services
          </Link>
          <span>&gt;</span>
          <span>{id !== undefined ? formData.title : "New"}</span>
        </div>
      </div>

      <div className="inputs-container bg-white">
        {Object.keys(validationErrors).length > 0 && (
          <div className="alert alert-danger">
            Please address the errors highlighted below.
          </div>
        )}
        <div className="product-info d-flex flex-column gap-4">
          <span className="product-info-title">SERVICE INFO</span>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Service Images</label>
              <ImageUploader
                images={formData.images}
                onFileInputChange={handleFileInputChange}
                onRemoveImage={handleRemoveImage}
                onSelectProfile={handleSelectProfile}
                selectedProfileIndex={selectedProfileIndex}
              />
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Service Title</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleTextInputChange}
                placeholder={"EX: Full Haircut"}
              />
              {validationErrors.title && (
                <span className="error-text text-danger">
                  {validationErrors.title[0]}
                </span>
              )}
            </div>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Service Category</label>
              <Select
                name="category"
                options={categories}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="service..."
                onChange={(selected) => {
                  handleSelectInputChange("category_id", selected);
                }}
                value={categories.find(
                  (option) => option.value === formData.category_id
                )}
              />
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Price</label>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleTextInputChange}
                placeholder={"EX: $50"}
              />
              {validationErrors.price && (
                <span className="error-text text-danger">
                  {validationErrors.price[0]}
                </span>
              )}
            </div>
            <div className="d-flex flex-column gap-1 input-container">
              <label className="fw-semibold">Service Unit</label>
              <Select
                name="service_unit"
                options={serviceUnitOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="EX: hourly"
                onChange={(selected) => {
                  handleSelectInputChange("service_unit", selected);
                }}
                value={serviceUnitOptions.find(
                  (option) => option.value === formData.service_unit
                )}
              />
              {validationErrors.service_unit && (
                <span className="error-text text-danger">
                  {validationErrors.service_unit[0]}
                </span>
              )}
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Pet Types</label>
              <Select
                name="pet"
                options={pets}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Pet Types..."
                isMulti
                isSearchable
                value={
                  selectedPetTypes.length > 0
                    ? pets.filter((pet) => selectedPetTypes.includes(pet.value))
                    : undefined
                }
                onChange={(selected) => {
                  handleSelectInputChange("pet_type_ids", selected);
                }}
              />
            </div>
            <div className="d-flex flex-column gap-1 input-container justify-content-between">
              <label className="fw-semibold">Service Place Availability</label>
              <CustomRadio
                options={placeOptions}
                setSelectedButton={setSelectedButton}
                selectedButton={selectedButton}
              />
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Service Description</label>
              <TextEditor
                value={formData.description1}
                onChange={(content) => {
                  const descriptionWithoutTags = content
                    .replace(/<p>/g, "")
                    .replace(/<\/p>/g, "");
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    description1: descriptionWithoutTags,
                  }));
                }}
                placeholder="Describe your service..."
              />
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Cancellation Policy</label>
              <textarea
                name="cancellation_policy"
                onChange={handleTextInputChange}
                className="w-100"
                placeholder="Write your cancellation policy..."
                value={formData.cancellation_policy}
              />
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Drop Off & Pick up Policy</label>
              <textarea
                name="drop_off_pick_up_policy"
                onChange={handleTextInputChange}
                className="w-100"
                placeholder="Write your drop off & pick up policy..."
                value={formData.drop_off_pick_up_policy}
              />
            </div>
          </div>
        </div>
        {Object.keys(validationErrors).length > 0 && (
          <div className="alert alert-danger mt-3">
            Please address the errors highlighted.
          </div>
        )}
        <div className="d-flex flex-md-row flex-column justify-content-md-end align-items-center gap-3 btn-containers">
          <button className={"btn btn-primary px-5 my-4"} onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceFormPage;
