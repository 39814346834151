const API_HOST = process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/auth/";

export function sendOtp(phoneNumber) {
    return fetch(API_HOST + "generate-otp", {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ phone_number: phoneNumber }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .catch(error => {
      console.error(error);
      return {staus: "error", message: "server error: " + error.message}
    });
  }

  export function verifyOtp(phoneNumber, otp) {
    return fetch(API_HOST + 'verify-otp', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ phone_number: phoneNumber, otp }),
    })
    .then(res => res.json())
    .catch(error => {
      console.error(error);
      return {staus: "error", message: "server error: " + error.message}
    });
  }
  