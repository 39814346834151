import React from "react";
import { Range } from "react-range";

const DoubleRange = ({ age, setAge }) => {

    const renderLabel = (value) => {
      return value >= 20 ? "20+" : value.toString();
    };

    return(
        <Range
            step={1}
            min={0}
            max={20}
            values={age}
            onChange={(values) => setAge(values)}
            renderTrack={({ props, children }) => (
                <div
                {...props}
                style={{
                    ...props.style,
                    height: "10px",
                    width: "90%",
                    backgroundColor: "#e0e0e0",
                }}
                className="me-4 rounded-1"
                >
                <div
                    style={{
                    position: "absolute",
                    height: "100%",
                    left: `${age[0]*5}%`,
                    width: `${(age[1] - age[0])*5}%`,
                    backgroundColor: "#fed4cb",
                    }}
                />
                {children}
                </div>
            )}
            renderThumb={({ props, index }) => (
                <div
                {...props}
                style={{
                    ...props.style,
                    height: "30px",
                    width: "10px",
                    backgroundColor: "#fd7d64",
                }}
                className="rounded-1"
                >
                <div
                    style={{
                    position: "absolute",
                    top: "-25px",
                    color: "#aaa",
                    fontSize: "16px",
                    fontWeight: "600",
                    }}
                >
                    {renderLabel(age[index])}
                </div>
                </div>
            )}
        />
    )

};

export default DoubleRange;
