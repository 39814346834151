import React, { useEffect } from "react";
import Select from "react-select";
import { useState } from "react";
import {
  add_employees,
  edit_employees,
  fetch_employee_create_form,
  fetch_employee_edit_form,
} from "../../../services/clinic/employees";
import SelectBox from "../common/SelectBox";
import { Link, Navigate, useParams } from "react-router-dom";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";

const EmployeeFormPage = () => {
  const { id } = useParams();
  const [shouldRedirectToIndex, setShouldRedirectToIndex] = useState(false);
  const today = new Date().toISOString().split("T")[0];

  const [formData, setFormData] = useState({
    name: "",
    job_title: "",
    expert_level: "trainee",
    phone_number_cc: "",
    phone_number: "",
    joining_date: today,
    city: "",
    area: "",
    street: "",
    location_details: "",
    addresses: [],
  });

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    let formDepCallback = () => {
      return fetch_employee_create_form();
    };
    if (id !== undefined) {
      formDepCallback = () => {
        return fetch_employee_edit_form(id);
      };
    }

    formDepCallback().then((res) => {
      if (res.status === "ok") {
        if (res.data) {
          setFormData((prevData) => ({
            ...prevData,
            ...res.data,
            city:
              res.data.addresses && res.data.addresses.length > 0
                ? res.data.addresses[0].city
                : prevData.city,
            street:
              res.data.addresses && res.data.addresses.length > 0
                ? res.data.addresses[0].street
                : prevData.street,
            area:
              res.data.addresses && res.data.addresses.length > 0
                ? res.data.addresses[0].area
                : prevData.area,
            location_details:
              res.data.addresses && res.data.addresses.length > 0
                ? res.data.addresses[0].location_details
                : prevData.location_details,
          }));
        }
      }
    });
  }, []);

  const handleTextInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    if (validationErrors[name]) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  const handleSelectInputChange = (name, value) => {
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handlePhoneNumberChange = (value) => {
    if (typeof value === "string") {
      const digitCount = (value.match(/\d/g) || []).length;
      if (digitCount > 1) {
        const parsedPhoneNumber = parsePhoneNumber(value);
        if (parsedPhoneNumber) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            phone_number: value,
          }));
          const phoneNumberCc = parsedPhoneNumber.countryCallingCode;
          setFormData((prevFormData) => ({
            ...prevFormData,
            phone_number_cc: phoneNumberCc,
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            phone_number: "",
          }));
          setFormData((prevFormData) => ({
            ...prevFormData,
            phone_number_cc: "",
          }));
        }
      } else {
        setFormData((prevFormData) => ({ ...prevFormData, phone_number: "" }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          phone_number_cc: "",
        }));
      }
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, phone_number: "" }));
      setFormData((prevFormData) => ({ ...prevFormData, phone_number_cc: "" }));
    }
  };

  const handleSave = () => {
    setValidationErrors({});
    if (id !== undefined) {
      edit_employees(id, formData).then((res) => {
        if (res.status === "ok") {
          setShouldRedirectToIndex(true);
        } else if (res.type === "validation") {
          setValidationErrors(res.validations);
        }
      });
    } else {
      add_employees(formData).then((res) => {
        if (res.status === "ok") {
          setShouldRedirectToIndex(true);
        } else if (res.type === "validation") {
          setValidationErrors(res.validations);
        }
      });
    }
  };

  if (shouldRedirectToIndex) {
    return <Navigate to={"/admin/employees"} />;
  }

  return (
    <div className="page-content products py-3 px-4 flex-grow-1 flex-shrink-1 bg-lightgray">
      <div className="mb-4">
        <span className="fw-bold dashboard-title">
          {id !== undefined ? "Edit Employee" : "Add Employee"}
        </span>
        <div className="breadcrumbs path fw-semibold gap-2 d-flex flex-row">
          <Link className={"link"} to={"/admin/"}>
            Dashboard
          </Link>
          <span>&gt;</span>
          <Link className={"link"} to={"/admin/employees"}>
            Employees
          </Link>
          <span>&gt;</span>
          <span>{id !== undefined ? formData.name : "New Employee"}</span>
        </div>
      </div>

      <div className="inputs-container bg-white">
        {Object.keys(validationErrors).length > 0 && (
          <div className="alert alert-danger">
            Please address the errors highlighted below.
          </div>
        )}
        <div className="product-info d-flex flex-column gap-4">
          <span className="product-info-title">EMPLOYEE INFO</span>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Employee Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleTextInputChange}
                placeholder={"EX: John Doe"}
              />
              {validationErrors.name && (
                <span className="error-text text-danger">
                  {validationErrors.name[0]}
                </span>
              )}
            </div>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Job Title</label>
              <input
                type="text"
                name="job_title"
                value={formData.job_title}
                onChange={handleTextInputChange}
                placeholder={"EX: Delivery Man"}
              />
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Expert Level</label>
              <SelectBox
                c_name={"expert_level"}
                c_options={[
                  { label: "trainee", value: "trainee" },
                  { label: "beginner", value: "beginner" },
                  { label: "intermediate", value: "intermediate" },
                  { label: "expert", value: "expert" },
                ]}
                c_placeholder={"Expert Level"}
                c_value={formData.expert_level}
                state_setter={(selectedValue) => {
                  handleSelectInputChange("expert_level", selectedValue);
                }}
              />
            </div>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Phone Number</label>
              <PhoneInput
                defaultCountry="AE"
                international
                withCountryCallingCode
                value={formData.phone_number}
                onChange={handlePhoneNumberChange}
              />
              {validationErrors.phone_number && (
                <span className="error-text text-danger">
                  {validationErrors.phone_number[0]}
                </span>
              )}
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Joining Date</label>
              <input
                type="date"
                name="joining_date"
                value={formData.joining_date}
                onChange={handleTextInputChange}
              />
            </div>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3"></div>
          </div>
        </div>
      </div>

      <div className="inputs-container bg-white mt-2">
        <div className="product-info d-flex flex-column gap-4">
          <span className="product-info-title">LOCATION DETAILS</span>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container">
              <label className="fw-semibold">City</label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleTextInputChange}
                placeholder={"EX: Dubai"}
              />
            </div>
            <div className="d-flex flex-column gap-1 input-container">
              <label className="fw-semibold">Area</label>
              <input
                type="text"
                name="area"
                value={formData.area}
                onChange={handleTextInputChange}
                placeholder={"EX: Middle East"}
              />
            </div>
          </div>
          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Street</label>
              <input
                type="text"
                name="street"
                value={formData.street}
                onChange={handleTextInputChange}
                placeholder={"street address..."}
              />
            </div>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3"></div>
          </div>
          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">More Detailed Location</label>
              <textarea
                type="text"
                name="location_details"
                value={formData.location_details}
                onChange={handleTextInputChange}
                placeholder="Add more details to best describe your location,  you can use the nearest landmarks beside you in the description..."
              />
            </div>
          </div>
        </div>
      </div>

      {Object.keys(validationErrors).length > 0 && (
        <div className="alert alert-danger mt-3">
          Please address the errors highlighted.
        </div>
      )}

      <div className="d-flex flex-md-row flex-column justify-content-md-end align-items-center gap-3 btn-containers">
        <button className={"btn btn-primary px-5 my-4"} onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default EmployeeFormPage;
