import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DynamicInput from "../DynamicInput";
import {
  fetch_dynamic_settings,
  save_settings,
} from "../../../services/settingsV2";
import { notify_promise } from "../../../services/utils/toasts";

const SettingsPage = () => {
  const [settingPageData, setSettingPageData] = useState({ loading: true });
  const [fieldValues, setFieldValues] = useState({});
  const [attachmentFieldValues, setAttachmentFieldValues] = useState({});
  const [inputErrors, setInputErrors] = useState({});
  const [generalError, setGeneralError] = useState("");

  const { tabName } = useParams();

  useEffect(() => {
    handleTabChange(tabName)
  }, [tabName])

  const handleAttachmentFieldValueChange = (
    groupIdentifier,
    fieldName,
    fieldValue
  ) => {
    setAttachmentFieldValues({
      ...attachmentFieldValues,
      [groupIdentifier]: {
        ...attachmentFieldValues[groupIdentifier],
        [fieldName]: fieldValue,
      },
    });
  };

  const handleFieldValueChange = (groupIdentifier, fieldName, fieldValue) => {
    setFieldValues({
      ...fieldValues,
      [groupIdentifier]: {
        ...fieldValues[groupIdentifier],
        [fieldName]: fieldValue,
      },
    });
  };

  const handleTabChange = (newActiveTab) => {
    setSettingPageData({
      ...settingPageData,
      activeTab: newActiveTab,
    });
  };

  const handleFormSubmit = () => {
    setInputErrors({});
    notify_promise(
      new Promise((resolve, reject) => {
        save_settings(fieldValues, attachmentFieldValues)
          .then(({ fieldsResponseData, attachmentsResponseData }) => {
            // Use fieldsData and attachmentsData here
            if (fieldsResponseData.status === "error") {
              if (
                fieldsResponseData.type === "validation" &&
                fieldsResponseData.validations !== undefined
              ) {
                let validationErrors = {};
                for (const [key, errors] of Object.entries(
                  fieldsResponseData.validations
                )) {
                  if (errors.length > 0) {
                    validationErrors[key] = errors[0];
                  }
                }
                setInputErrors(validationErrors);
              }
            }

            const fieldsHaveGeneralErrors =
              fieldsResponseData.status === "error" &&
              fieldsResponseData.type === "general";
            const attachmentsHaveGeneralErrors =
              attachmentsResponseData.status === "error" &&
              attachmentsResponseData.type === "general";

            if (fieldsHaveGeneralErrors) {
              setGeneralError(fieldsResponseData.message);
            }
            if (attachmentsHaveGeneralErrors) {
              setGeneralError(attachmentsResponseData.message);
            }
            resolve();
          })
          .catch((error) => {
            console.log(error);
          });
      }, "Settings Updated !")
    );
  };

  useEffect(() => {
    fetch_dynamic_settings().then((settingsAPIResponse) => {
      if (tabName !== "" && tabName !== undefined) {
        settingsAPIResponse.activeTab = tabName;
      }

      setSettingPageData(settingsAPIResponse);
      setFieldValues(getFieldValuesFromResponse(settingsAPIResponse.settings));
    });
  }, []);

  if (settingPageData.loading) {
    return (
      <div className="d-flex p-5 align-items-center justify-content-center">
        <p>Loading</p>
      </div>
    );
  }

  return (
    <div className="page-content py-3 px-4 bg-lightgray">
      <div className="form-renders">
        <SettingsPageHeader
          settingGroups={settingPageData.settings}
          activeTab={settingPageData.activeTab}
          handleTabChange={handleTabChange}
          handleSaveButton={handleFormSubmit}
        />
        <div className="tab-content" id="myTabContent">
          {generalError.length > 0 && (
            <div className={"py-3 px-4 flex-grow-1 flex-shrink-1 form"}>
              <span
                className={"d-block full-width p-3 rounded-2 bg-white error"}
              >
                {generalError}
              </span>
            </div>
          )}

          {Object.keys(settingPageData.settings).map((key, index) => {
            const settingsTabContent = settingPageData.settings[key];
            const isActive = key === settingPageData.activeTab;
            const activeClass = isActive ? " show active" : "";
            return (
              <div
                key={key}
                className={
                  "py-3 px-4 flex-grow-1 flex-shrink-1 bg-lightgray tab-pane fade" +
                  activeClass
                }
                id={key}
                role="tabpanel"
                aria-labelledby={key + "-tab"}
              >
                <SettingsTabContent
                  key={key + "-content"}
                  fieldValues={fieldValues}
                  content={settingsTabContent}
                  inputErrors={inputErrors}
                  handleInputChange={handleFieldValueChange}
                  handleAttachmentInputChange={handleAttachmentFieldValueChange}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

// Renders the different settings tabs, handles their logic & renders the global settings Save button
const SettingsPageHeader = ({
  settingGroups,
  activeTab,
  handleTabChange,
  handleSaveButton,
}) => {
  const navigate = useNavigate();
  return (
    <div className="d-flex justify-content-between">
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {Object.keys(settingGroups).map((key, index) => {
          const form = settingGroups[key];
          const isActive = key === activeTab;
          const activeClass = isActive ? "active" : "";

          return (
            <li
              key={key}
              className={`nav-item ${activeClass}`}
              role="presentation"
              onClick={() => {
                handleTabChange(key);
              }}
            >
              <button
                className={"nav-link " + activeClass}
                id={key + "-tab"}
                data-bs-toggle="tab"
                data-bs-target={"#" + key}
                type="button"
                role="tab"
                aria-controls={key}
                aria-selected={isActive}
                onClick={() => {
                  navigate(`/admin/settings/${key}`);
                }}
              >
                {form.label}
              </button>
            </li>
          );
        })}
      </ul>
      <div className="d-flex flex-md-row flex-column justify-content-md-end align-items-center gap-3 btn-containers px-4">
        <button
          className={"btn btn-primary pl-5 pr-5"}
          onClick={handleSaveButton}
        >
          Save
        </button>
      </div>
    </div>
  );
};

// Each tab in the settings page may have several forms (e.g: personal info, change password, etc...)
// This component creates different UI "groups" for each collection of bundled fields
const SettingsTabContent = ({
  fieldValues,
  content,
  handleInputChange,
  handleAttachmentInputChange,
  inputErrors,
}) => {
  return (
    <div className=" flex-grow-1 flex-shrink-1 bg-lightgray">
      {content.groups.map((group, index) => {
        const handleGroupInputFieldChange = (name, value) => {
          handleInputChange(group.groupIdentifier, name, value);
        };
        const handleGroupAttachmentInputChange = (name, value) => {
          handleAttachmentInputChange(group.groupIdentifier, name, value);
        };

        return (
          <div key={index} className="inputs-container bg-white mb-2">
            <div className="product-info form row">
              <div className="product-info-title mb-2 d-flex justify-content-between">
                <span>{group.title}</span>
                <span>
                  {group.fields.map((field, index) => {
                    return field.name === "id" ? "#" + field.value : "";
                  })}
                </span>
              </div>
              {group.fields.map((field, index) => {
                if (field.containerClass === undefined) {
                  field.containerClass = "col-md-6 mb-2";
                }
                return (
                  <div key={index} className={field.containerClass}>
                    <DynamicInput
                      key={index + "-input"}
                      fieldData={field}
                      fieldValue={
                        fieldValues[group.groupIdentifier][field.name]
                      }
                      handleChange={handleGroupInputFieldChange}
                      handleAttachmentChange={handleGroupAttachmentInputChange}
                    />

                    {inputErrors[field.name] !== undefined && (
                      <span key={index + "-error"} className={"error"}>
                        {inputErrors[field.name]}
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const getFieldValuesFromResponse = (settings) => {
  const initMap = {};
  Object.keys(settings).forEach((tab) => {
    settings[tab].groups.forEach((group) => {
      let groupFields = {};
      group.fields.forEach((field) => {
        groupFields[field.name] = field.value || "";
      });

      initMap[group.groupIdentifier] = groupFields;
    });
  });

  return initMap;
};

export default SettingsPage;
