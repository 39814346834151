const API_HOST =
  process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/clinic/appointments/";

export const fetch_slots = (options) => {
  const encodedQueryParams = new URLSearchParams(options);

  return fetch(`${API_HOST}?${encodedQueryParams}`, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};


export const fetch_appointment_create_form = () => {
  return fetch(API_HOST + "create", {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const fetch_appointment_edit_form = (id) => {
  return fetch(API_HOST + id + "/edit", {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const add_appointments = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return fetch(API_HOST, {
    method: "POST",
    credentials: "include",
    secure: true,
    body: formData,
    headers: {},
  }).then((res) => res.json());
};

export const edit_appointments = (id, data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return fetch(`${API_HOST}${id}?_method=PUT`, {
    method: "POST",
    credentials: "include",
    secure: true,
    body: formData,
    headers: {},
  }).then((res) => res.json());
};


export const delete_appointment = (id) => {
  return fetch(`${API_HOST}${id}`, {
    method: "DELETE",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const accept_appointment = (bookingId) => {
  return fetch(`${API_HOST}${bookingId}/accept`, {
    method: "PUT",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const refuse_appointment = (bookingId) => {
  return fetch(`${API_HOST}${bookingId}/refuse`, {
    method: "PUT",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const fulfill_appointment = (bookingId) => {
  return fetch(`${API_HOST}${bookingId}/fulfill`, {
    method: "PUT",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const assign_employee = (bookingId, employeeId) => {
  return fetch(`${API_HOST}${bookingId}/assign-employee/${employeeId}`, {
    method: "PUT",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const change_timing = (bookingId, data) => {
  console.log(data);

  return fetch(`${API_HOST}${bookingId}/change-timing`, {
    method: "POST",
    credentials: "include",
    secure: true,
    body: data,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};
