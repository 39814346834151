import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  add_documents,
  edit_documents,
  fetch_document_edit_form,
  fetch_document_create_form,
} from "../../../services/admin/documents";
import { Link, Navigate, useParams } from "react-router-dom";
import TextEditor from "../TextEditor";

const DocumentFormPage = () => {
  const { id } = useParams();

  const [shouldRedirectToIndex, setShouldRedirectToIndex] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    body: "",
  });

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    let formDepCallback = () => {
      return fetch_document_create_form();
    };
    if (id !== undefined) {
      formDepCallback = () => {
        return fetch_document_edit_form(id);
      };
    }

    formDepCallback().then((res) => {
      if (res.status === "ok") {
        if (res.data) {
          setFormData(res.data);
        }
      }
    });
  }, []);

  const handleTextInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (validationErrors[name]) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  const handleSave = () => {
    console.log(formData);
    setValidationErrors({});
    if (id !== undefined) {
      edit_documents(id, formData).then((res) => {
        if (res.status === "ok") {
          setShouldRedirectToIndex(true);
        } else if (res.type === "validation") {
          setValidationErrors(res.validations);
        }
      });
    } else {
      add_documents(formData).then((res) => {
        if (res.status === "ok") {
          setShouldRedirectToIndex(true);
        } else if (res.type === "validation") {
          setValidationErrors(res.validations);
        }
      });
    }
  };

  if (shouldRedirectToIndex) {
    return <Navigate to={"/admin/documents/list"} />;
  }

  return (
    <div className="page-content py-3 px-4 flex-grow-1 flex-shrink-1 bg-lightgray">
      <div className="mb-4">
        <span className="fw-bold dashboard-title">
          {id !== undefined ? "Edit Document" : "Add Document"}
        </span>
        <div className="breadcrumbs path fw-semibold gap-2 d-flex flex-row">
          <Link className={"link"} to={"/admin/"}>
            Dashboard
          </Link>
          <span>&gt;</span>
          <Link className={"link"} to={"/admin/documents/list"}>
            Documents
          </Link>
          <span>&gt;</span>
          <span>{id !== undefined ? formData.title : "New Document"}</span>
        </div>
      </div>

      <div className="inputs-container bg-white">
        {Object.keys(validationErrors).length > 0 && (
          <div className="alert alert-danger">
            Please address the errors highlighted below.
          </div>
        )}
        <div className="product-info d-flex flex-column gap-4">
          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Document Title</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleTextInputChange}
                placeholder={"Document Title"}
              />
              {validationErrors.title && (
                <span className="error-text text-danger">
                  {validationErrors.title[0]}
                </span>
              )}
            </div>
          </div>

          <div className="row pr-5">
            <div className="col-12 mb-5">
              <label className="fw-semibold">Document Body</label>
              <TextEditor
                value={formData.body}
                onChange={(content) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    body: content,
                  }));
                }}
                height={500}
              />
              {validationErrors.body && (
                <span className="error-text text-danger">
                  {validationErrors.body[0]}
                </span>
              )}
            </div>
          </div>
        </div>

        {Object.keys(validationErrors).length > 0 && (
          <div className="alert alert-danger mt-3">
            Please address the errors highlighted.
          </div>
        )}

        <div className="d-flex flex-md-row flex-column justify-content-md-end align-items-center gap-3 btn-containers">
          <button className={"btn btn-primary p-10-50"} onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default DocumentFormPage;
