import React, { useEffect, useState } from "react";
import {
  fetch_users,
  delete_user,
  export_users,
} from "../../../services/admin/users";
import ItemListingTable, {
  getTableUpdateCallback,
} from "../common/ItemListingTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import ShopPopup from "./ShopPopup";
import { notify_promise } from "../../../services/utils/toasts";
import ListingPageControls from "../common/ListingPageControls";

const UserListingPage = ({ pageState, setPageState }) => {

  const [users, setUsers] = useState([]);

  const [selectedShop, setSelectedShop] = useState(undefined);

  const [fields, setFields] = useState([]);
  const [pagination, setPagination] = useState({});

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search"));
  const [latestSearchQuery, setLatestSearchQuery] = useState(
    searchParams.get("search")
  );

  const apiCall = [
    fetch_users,
    { page: 0, search: search ? search : "" },
  ];

  const handleDelete = (id) => {
    notify_promise(
      new Promise((resolve, reject) => {
        delete_user([id])
          .then((res) => {
            setUsers((prevPets) => {
              return prevPets.filter((p) => {
                return p.id !== id;
              });
            });
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  useEffect(() => {
    setSearch(searchParams.get("search"));
  }, [searchParams.get("search")]);

  useEffect(() => {
    setLatestSearchQuery(search);
  }, [search]);

  useEffect(() => {
    updateTableInfo(0);
  }, [latestSearchQuery]);

  const updateTableInfo = getTableUpdateCallback(
    {
    apiCall: apiCall,
    dataSetter: setUsers,
    paginationSetter: setPagination,
    fieldSetter: setFields,
    buttons: [
      {
        template: "edit",
      },
      {
        template: "delete",
        onClick: (id) => {
          handleDelete(id);
        },
      },
    ]}
  );

  return (
    <div className="page-content py-3 px-4 bg-lightgray">
      <ShopPopup shop={selectedShop} />

      <ListingPageControls
        exportApi={export_users}
        type="user"
        hasSingleTitle={true}
        resultType="user"
        setSearch={setSearchParams}
      />

      <ItemListingTable
        fields={fields}
        data={users}
        pagination={pagination}
        updateTableInfo={updateTableInfo}
      />
    </div>
  );
};

export default UserListingPage;
