const API_HOST =
  process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/clinic/services/";

const SEARCH_API =
  process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/service/search/top";

export const fetch_services = (options) => {
  const encodedQueryParams = new URLSearchParams(options);

  return fetch(`${API_HOST}?${encodedQueryParams}`, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const fetch_service = (id) => {
  return fetch(API_HOST + id, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const fetch_service_create_form = () => {
  return fetch(API_HOST + "create", {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const fetch_service_edit_form = (id) => {
  return fetch(API_HOST + id + "/edit", {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const add_services = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  formData.append("picture", data.picture);
  return fetch(API_HOST, {
    method: "POST",
    credentials: "include",
    secure: true,
    body: formData,
    headers: {},
  }).then((res) => res.json());
};

export const edit_services = (id, data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  if (data.img_picture instanceof File) {
    formData.append("img_picture", data.img_picture);
  }
  return fetch(`${API_HOST}${id}?_method=PUT`, {
    method: "POST",
    credentials: "include",
    secure: true,
    body: formData,
    headers: {},
  }).then((res) => res.json());
};

export const export_services = () => {
  return fetch(API_HOST + "export", {
    method: "POST",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const search_services = (searchQuery) => {
  return fetch(`${SEARCH_API}?query=${searchQuery}&type=service`, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const delete_service = (id) => {
  return fetch(`${API_HOST}${id}`, {
    method: "DELETE",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};
