const API_HOST =
  process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/shelter/transfers/";

export const fetch_transfers = (options) => {
  const encodedQueryParams = new URLSearchParams(options);
  return fetch(`${API_HOST}?${encodedQueryParams}`, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const add_transfers = (data) => {
  return fetch(API_HOST, {
    method: "POST",
    credentials: "include",
    secure: true,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const approve_transfer = (id) => {
  return fetch(`${API_HOST}${id}/approve`, {
    method: "PUT",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const reject_transfer = (id) => {
  return fetch(`${API_HOST}${id}/reject`, {
    method: "PUT",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const cancel_transfer = (id) => {
  return fetch(`${API_HOST}${id}`, {
    method: "DELETE",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};
