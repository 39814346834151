import React from 'react';
import { Link } from 'react-router-dom';

const SignUp = (props) => {
    return (
        <div className={props.class}>
            <h2 className="mb-1 fw-bold">Join Pawpaws  For Free</h2>
            <p className="form-help">Pawpaws opens up a world of opportunities for you, your registration will take 2-3 minutes. Prepare the required files listed below before starting the registration.</p>


            <div className="info-item mb-3">
                <h1 className="title mb-1">1. Owner Info</h1>
                <p className="form-help m-0">Name, email, phone, ID etc...</p>
            </div>
            <div className="info-item mb-3">
                <h1 className="title mb-1">2. Business Details</h1>
                <p className="form-help m-0">Shop Name, Logo - Profile Picture, Trade License, Location Type Of Service etc...</p>
            </div>

            <Link to={'/signupData'}><button type="submit" className="btn btn-primary py-2 mt-4 fw-bold w-100">Start Registration</button></Link>

        </div>
    );
}

export default SignUp;
