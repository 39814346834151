import React, { useEffect, useState } from "react";
import pdf from "../resources/themes/dashboard-v1/icons/pdf.svg";

const SingleFileUploader = ({ options, type }) => {
  const [photo, setPhoto] = useState(null);

  useEffect(() => {
    if (options.currentPhoto !== undefined) {
      setPhoto(options.currentPhoto);
    }
  }, [options]);

  const handleAddImage = (e) => {
    e.preventDefault();
    const fileInput = document.getElementById(options.name + "Upload");
    fileInput.click();
  };

  const onPhotoChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPhoto(type === "image" ? reader.result : file);
      if (options.name === "businessLogo" || options.type === "file") {
        options.onPhotoChange(file);
        if (options.type === "file") {
          setPhoto(file);
        }
      } else if (options.onPhotoChange !== undefined) {
        options.onPhotoChange(options.name, reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3 ">
      <label className="fw-semibold">
        {options.label === undefined ? "" : options.label}
      </label>
      <div className="product-image w-100 position-relative">
        <div className="uploaded-images-container">
          {photo && (
            <div className="uploaded-image">
              {photo instanceof File
                ? (() => {
                    if (type === "image") {
                      return (
                        <img
                          height={options.height || 150}
                          src={URL.createObjectURL(photo)}
                          alt=""
                        />
                      );
                    } else {
                      return (
                        <>
                          <img height={70} src={pdf} alt="" />
                          <span className="fs-5">{photo.name}</span>
                        </>
                      );
                    }
                  })()
                : (() => {
                    if (type === "image") {
                      return (
                        <img
                          height={options.height || 150}
                          src={photo}
                          alt=""
                        />
                      );
                    } else {
                      return (
                        <>
                          <img height={70} src={pdf} alt="" />
                          <span className="fs-5">{photo.name}</span>
                        </>
                      );
                    }
                  })()}
            </div>
          )}
          <div className="add-image-button d-flex justify-content-start">
            <button onClick={handleAddImage}>+</button>
            <input
              id={options.name + "Upload"}
              hidden
              type="file"
              name={options.name}
              onChange={onPhotoChange}
              accept={`${
                type === "image"
                  ? "image/jpeg, image/png, image/gif"
                  : ".pdf, application/pdf"
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleFileUploader;
