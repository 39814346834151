import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ItemListingTable, {
  getTableUpdateCallback,
} from "../../common/ItemListingTable";
import {
  edit_adoptions_status,
  fetch_shop_adoptions,
} from "../../../../services/shelters/adoptions";

import { generate_pet_invoice } from "../../../../services/shelters/pets";

import { notify_promise } from "../../../../services/utils/toasts";
import DataFilterButtons, { STYLE_LINKS } from "../../common/DataFilterButtons";
import AdoptionRequestPopup from "./AdoptionRequestPopup";

const AdoptionListingPage = () => {
  const [adoptions, setAdoptions] = useState([]);
  const [selectedAdoption, setSelectedAdoption] = useState(undefined);
  const [modalCounter, setModalCounter] = useState(0);

  const [fields, setFields] = useState([]);
  const [pagination, setPagination] = useState({});

  const [filters, setFilters] = useState([]);
  const [activeFilter, setActiveFilter] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { requestedAdoptionId } = useParams();
  const navigate = useNavigate();

  const openModal = (index) => {
    setSelectedAdoption(adoptions[index]);
    const popup = document.getElementById("adoptionPopup");
    popup.style.display = "flex";
  };

  const sendInvoice = (petId, petOwnerId, petOwnerEmail) => {
    notify_promise(
      new Promise((resolve, reject) => {
        generate_pet_invoice(petId, petOwnerId, petOwnerEmail)
          .then((res) => {
            resolve(res);
          })
          .catch(reject);
      }),
      "Invoice generation in progress! Watch your inbox for the download link soon",
      "📧"
    );
  };

  const handleApprove = (id) => {
    notify_promise(
      new Promise((resolve, reject) => {
        edit_adoptions_status(id, 2)
          .then((res) => {
            setAdoptions((adoptions) =>
              adoptions.filter((item) => item.id !== id)
            );
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  const handleReject = (id) => {
    notify_promise(
      new Promise((resolve, reject) => {
        edit_adoptions_status(id, 3)
          .then((res) => {
            setAdoptions((adoptions) =>
              adoptions.filter((item) => item.id !== id)
            );
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  const apiCall = [
    fetch_shop_adoptions,
    { page: 1, filter: activeFilter, perPage: perPage },
  ];

  const updateTableInfo = getTableUpdateCallback({
    apiCall: apiCall,
    dataSetter: setAdoptions,
    paginationSetter: setPagination,
    fieldSetter: setFields,
    buttons: [],
    setFilters: setFilters,
    setActiveFilter: setActiveFilter,
    type: "adoption",
    handleApprove: handleApprove,
    handleReject: handleReject,
    openModal: openModal,
  });

  useEffect(() => {
    updateTableInfo(1);
  }, [perPage]);

  return (
    <div className="page-content py-3 px-4 bg-lightgray">
      <AdoptionRequestPopup
        adoption={selectedAdoption}
        setAdoptions={setAdoptions}
        setSelectedAdoption={setSelectedAdoption}
      />
      <div className="d-flex flex-xl-row flex-column justify-content-between align-items-center mb-4">
        {filters.length > 0 ? <DataFilterButtons filters={filters} /> : ""}
      </div>
      <div className={"text-right"}></div>
      <ItemListingTable
        fields={fields}
        data={adoptions}
        pagination={pagination}
        updateTableInfo={updateTableInfo}
        modalTrigger={openModal}
        handleApprove={handleApprove}
        handleReject={handleReject}
        sendInvoice={sendInvoice}
        requestedAdoptionId={
          modalCounter === 0 ? requestedAdoptionId : undefined
        }
        setModalCounter={setModalCounter}
        setPerPage={setPerPage}
        perPage={perPage}
      />
    </div>
  );
};

export default AdoptionListingPage;
