const API_HOST =
  process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/reports";

const SEARCH_API =
  process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/search/top";

export const fetch_reports = (options) => {
  const encodedQueryParams = new URLSearchParams(options);
  return fetch(`${API_HOST}?${encodedQueryParams}`, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const search_reports = (searchQuery) => {
  return fetch(`${SEARCH_API}?query=${searchQuery}&type=blog`, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};