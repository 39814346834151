import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import ItemListingTable, {
  getTableUpdateCallback,
} from "../common/ItemListingTable";
import {
  fetch_bookings,
  accept_booking,
  refuse_booking,
  delete_booking,
  fulfill_booking,
  assign_employee,
} from "../../../services/clinic/bookings";
import ListingPageControls from "../common/ListingPageControls";
import { notify_promise } from "../../../services/utils/toasts";
import BookingPopup from "./bookings/BookingPopup";

const BookingListingPage = () => {
  const [fields, setFields] = useState([]);
  const [data, setData] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(undefined);
  const [employeesOption, setEmployeesOption] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filters, setFilters] = useState([]);
  const [modalCounter, setModalCounter] = useState(0);
  const { requestedBookingId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search"));
  const [latestSearchQuery, setLatestSearchQuery] = useState(
    searchParams.get("search")
  );
  const [activeFilter, setActiveFilter] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const navigate = useNavigate();

  let { bookingStatus } = useParams();

  const filterMapping = {
    0: "Recent",
    1: "Accepted",
    2: "Fulfilled",
    3: "Refused",
    4: "Cancelled"
  };

  let numericBookingStatus = Object.keys(filterMapping).find(
    (key) => filterMapping[key] === bookingStatus
  );

  if (!numericBookingStatus) numericBookingStatus = "0";

  const openModal = (index) => {
    setSelectedBooking(data[index]);
    const popup = document.getElementById("bookingPopup");
    popup.style.display = "flex";
  };

  const handleApprove = (id) => {
    notify_promise(
      new Promise((resolve, reject) => {
        accept_booking([id])
          .then((res) => {
            setData((data) => data.filter((item) => item.id !== id));
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  const handleReject = (id) => {
    notify_promise(
      new Promise((resolve, reject) => {
        refuse_booking([id])
          .then((res) => {
            setData((data) => data.filter((item) => item.id !== id));
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  const handleDelete = (id) => {
    notify_promise(
      new Promise((resolve, reject) => {
        delete_booking([id])
          .then((res) => {
            setData((prev) => {
              return prev.filter((p) => {
                return p.id !== id;
              });
            });
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  const handleFulfill = (id) => {
    notify_promise(
      new Promise((resolve, reject) => {
        fulfill_booking([id])
          .then((res) => {
            setData((data) => data.filter((item) => item.id !== id));
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  const assignEmployee = (id, employee_id) => {
    notify_promise(
      new Promise((resolve, reject) => {
        assign_employee(id, employee_id)
          .then((res) => {
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  const apiCall = [
    fetch_bookings,
    {
      page: 0,
      status: numericBookingStatus,
      search: search ? search : "",
      perPage: perPage,
    },
  ];

  const updateTableInfo = getTableUpdateCallback({
    apiCall: apiCall,
    dataSetter: setData,
    paginationSetter: setPagination,
    fieldSetter: setFields,
    buttons: [],
    setFilters: setFilters,
    setActiveFilter: setActiveFilter,
    filterMapping: filterMapping,
    navigate: navigate,
    numericStatus: numericBookingStatus,
    type: "booking",
    handleApprove: handleApprove,
    handleReject: handleReject,
    handleDelete: handleDelete,
    handleFulfill: handleFulfill,
    setOptions: setEmployeesOption,
    openModal: openModal,
  });

  useEffect(() => {
    updateTableInfo(0);
    setSearch(searchParams.get("search"));
    setLatestSearchQuery(search);
  }, [numericBookingStatus, searchParams.get("search"), latestSearchQuery, perPage]);

  return (
    <div className="page-content py-3 px-4 bg-lightgray">
      <BookingPopup
        booking={selectedBooking}
        setBookings={setData}
        setSelectedBooking={setSelectedBooking}
      />
      <ListingPageControls
        type="appointment"
        buttonAndTabs={true}
        resultType="appointment"
        filters={filters}
        setSearch={setSearchParams}
        noExport={true}
        noAdd={true}
      />
      <ItemListingTable
        updateTableInfo={updateTableInfo}
        fields={fields}
        data={data}
        options={employeesOption}
        pagination={pagination}
        modalTrigger={openModal}
        handleApprove={handleApprove}
        handleReject={handleReject}
        handleFulfill={handleFulfill}
        assign={assignEmployee}
        requestedBookingId={modalCounter === 0 ? requestedBookingId : undefined}
        setModalCounter={setModalCounter}
        customTDs={{
          service_name: (item) => (
            <ul className={"text-start"}>
              {item["service_names"].map(function (serviceName, index) {
                return (
                  <li className={"mb-1"} key={index}>
                    {serviceName}
                  </li>
                );
              })}
            </ul>
          ),
        }}
        setPerPage={setPerPage}
        perPage={perPage}
      />
    </div>
  );
};

export default BookingListingPage;
