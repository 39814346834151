import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import useLocalState from "@phntms/use-local-state";
import { auth_ping } from "../services/auth";

const PrivateRoutes = () => {
  const [userLoggedIn, setUserLoggedIn] = useLocalState("user_logged_in");

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const res = await auth_ping();
        if (res.status === "ok") {
          setUserLoggedIn(true);
        } else if (res.status === "error") {
          setUserLoggedIn(false);
        }
      } catch (error) {
        console.error("Error checking authentication:", error);
        setUserLoggedIn(false);
      }
    };

    checkAuthentication();
  }, []);

  console.log(userLoggedIn);

  return userLoggedIn === true ? <Outlet /> : <Navigate to={"/login"} />;
};

export default PrivateRoutes;
